import React, { useRef, useEffect, useState } from "react";
import * as THREE from "three";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls";
import { STLLoader } from "three/examples/jsm/loaders/STLLoader";
import { useLocation } from "react-router-dom";
import {CSG} from "three-csg-ts";

const MeshManipulation = () => {
  const mountRef = useRef(null);
  const location = useLocation();
  const { prep, crown } = location.state || {};
  const [loading, setLoading] = useState(true);
  const [sceneObjects, setSceneObjects] = useState({}); // Store loaded objects by name
  let scene

  useEffect(() => {
    if (!prep && !crown) {
      console.warn("No STL files provided");
      setLoading(false);
      return;
    }

    scene = new THREE.Scene();
    scene.background = new THREE.Color(0xf4f4f4);

    const camera = new THREE.PerspectiveCamera(
      75,
      window.innerWidth / window.innerHeight,
      0.1,
      1000
    );
    camera.position.set(-50, -50, 50);

    const renderer = new THREE.WebGLRenderer({ antialias: true });
    renderer.setSize(window.innerWidth, window.innerHeight);
    renderer.toneMapping = THREE.ACESFilmicToneMapping; // Better tone mapping
    mountRef.current.appendChild(renderer.domElement);

    const loader = new STLLoader();
    const controls = new OrbitControls(camera, renderer.domElement);

    const loadMesh = (file, color, name) => {
      return new Promise((resolve, reject) => {
        loader.load(
          URL.createObjectURL(file),
          (geometry) => {
            geometry.computeVertexNormals();
            const material = new THREE.MeshPhysicalMaterial({
              color,
              side: THREE.DoubleSide,
              roughness: 0.3,
              metalness: 0.6,
              clearcoat: 0.9,
              clearcoatRoughness: 0.2,
              sheen: 0.5,
            });

            const mesh = new THREE.Mesh(geometry, material);
            mesh.name = name; // Assign a unique name to the mesh
            resolve(mesh);
          },
          undefined,
          (error) => reject(error)
        );
      });
    };
    let objects = {};

    const loadMeshes = async () => {
      try {
        const promises = [];
        if (prep) promises.push(loadMesh(prep, 0x00ff00, "prep")); // Green
        if (crown) promises.push(loadMesh(crown, 0xff00ff, "crown")); // Magenta

        const meshes = await Promise.all(promises);

        meshes.forEach((mesh) => {
          scene.add(mesh);
          objects[mesh.name] = mesh; // Store mesh by name in the state
        });

        setSceneObjects(objects); // Update state with loaded objects
      } catch (error) {
        console.error("Error loading STL files:", error);
      } finally {
        setLoading(false);
      }
    };

    loadMeshes();

    // Lighting Setup
    const ambientLight = new THREE.AmbientLight(0xffffff, 0.8); // Soft light
    scene.add(ambientLight);

    const directionalLight = new THREE.DirectionalLight(0xffffff, 1.2);
    directionalLight.position.set(10, 10, 20);
    directionalLight.castShadow = true;
    scene.add(directionalLight);

    const pointLight = new THREE.PointLight(0xffffff, 0.8);
    pointLight.position.set(-10, -10, 10);
    scene.add(pointLight);

    // Animation loop
    const animate = () => {
      requestAnimationFrame(animate);
      controls.update();
      renderer.render(scene, camera);
    };

    animate();

    return () => {
      if (prep) URL.revokeObjectURL(URL.createObjectURL(prep));
      if (crown) URL.revokeObjectURL(URL.createObjectURL(crown));
      mountRef.current.removeChild(renderer.domElement);
    };
  }, [prep, crown]);

  useEffect(() => {

    if(sceneObjects.prep && sceneObjects.crown) {
      const prepMesh = sceneObjects.prep;
      const crownMesh = sceneObjects.crown;
      

    }
    
  }, [sceneObjects]);

  // Function to get an object by name
  const getObjectByName = (name) => {
    return sceneObjects[name] || null;
  };

  return (
    <div>
      {loading && <div>Loading STL files...</div>}
      <div ref={mountRef} />
      <button
        onClick={() => {
          const prepMesh = getObjectByName("prep");
          const crownMesh = getObjectByName("crown");
          console.log("Prep Mesh:", prepMesh);
          console.log("Crown Mesh:", crownMesh);
        }}
      >
        Get Loaded Objects
      </button>
    </div>
  );
};

export default MeshManipulation;
