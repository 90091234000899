// eslint-disable-next-line
import React, { useEffect, useState, memo, useRef, useLayoutEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { TransformControls } from 'three/examples/jsm/controls/TransformControls.js';
import { ClipLoader } from 'react-spinners';
import { Tooltip, OverlayTrigger } from 'react-bootstrap';
import { ReactComponent as PrepIcon } from '../assets/Prep.svg';
import { ReactComponent as CrownIcon } from '../assets/Magic.svg';
import { ReactComponent as MySVG } from '../assets/Vector 14.svg';
import { ReactComponent as MySVG1 } from '../assets/Upper jaw.svg';
import { ReactComponent as MySVG2 } from '../assets/Tile.svg';
import { ReactComponent as MySVG3 } from '../assets/Grid.svg';
import { ReactComponent as MySVG4 } from '../assets/Model.svg';
import { ReactComponent as MySVG5 } from '../assets/delete.svg';
import { ReactComponent as MySVG7 } from '../assets/Lower jaw inactive.svg';
import { ReactComponent as MySVG8 } from '../assets/Upper jaw inactive.svg';
import { ReactComponent as MySVG9 } from '../assets/Lower jaw.svg';
import { ReactComponent as MySVG10 } from '../assets/Check.svg';
import { ReactComponent as Delete } from '../assets/Close Icon.svg';
import { ReactComponent as View } from '../assets/Group.svg';
import { ReactComponent as Toolbox } from '../assets/Toolbox.svg';
import { ReactComponent as Smooth } from '../assets/Smooth.svg';
import { ReactComponent as Brush } from '../assets/Proximal.svg';
import { ReactComponent as Margin } from '../assets/Margin.svg';
import { ReactComponent as Visiblity } from '../assets/visibility.svg';
import { ReactComponent as ExportIcon } from '../assets/Export.svg';
import ThreeJSManager from "./Crown Component/ThreeD"
import KdTree from 'static-kdtree'; // Import the KD-tree library
import { toCreasedNormals } from 'three/examples/jsm/utils/BufferGeometryUtils.js'
import { STLExporter } from 'three/examples/jsm/exporters/STLExporter';
import * as U from "./Crown Component/utils"
import { PCDLoader } from 'three/examples/jsm/loaders/PCDLoader';
import JSZip, { folder } from 'jszip'; 
import { remove } from 'three/examples/jsm/libs/tween.module.js';


const Crown = () => {
  const location = useLocation();
  const { file1, file2, crown,marginCurve,uuid_crown,crownFirst, marginCenter,allowed_points ,thickness, position, axis, prepView, antaView, inner_surface } = location.state || {};
  const [prep, setprep] = useState(file1)
  const [anta, setanta] = useState(file2)
  const navigate = useNavigate();
  const [brushingPoints, setBrushingPoints] = useState(null);
  const [marginstartpoint, setMarginstartpoint] = useState(null);
  const [marginendpoint, setMarginendpoint] = useState(null);
  const [Adjustmentpanel, setAdjustmentpanel] = useState(true);
  const [selectedOption, setSelectedOption] = useState('medium');
  const [crownView, setcrownView] = useState(false);
  const [tool, setTool] = useState(false);
  const [initialCameraPosition, setInitialCameraPosition] = useState(position ? position : new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028));
  const [showForm, setShowForm] = useState(false)
  const [shoeNewcrown, setshoeNewcrown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const [name, setName] = useState('');
  const [brushing, setBrushing] = useState(false);
  const [allowed_points_list, setAllowedPoints] = useState([]);
  const [margin, setMargin] = useState(false);
  const [isRaycasterActive, setIsRaycasterActive] = useState(false);
  const raycasterSphereRef = useRef(null);
  const [selectedCoordinates, setSelectedCoordinates] = useState(null);
  const [crownforrequest, setCrownforrequest] = useState(null);
  const [indexforrequest, setIndexforrequest] = useState(null);
  const [crownarray, setCrownarray] = useState(crown);
  const primaryColor = '#213F99';
  const [category, setCategory] = useState('molar');
  const [marginIndex, setMarginIndex] = useState(null);
  const [smoothpoints, setSmoothpoints] = useState([]);
  const [crownColor, setCrownColor] = useState('#ffffff');
  const [anatomy, setAnatomy] = useState(false);
  const [distalcenter, setdestialcenter] = useState(false);
  const [mesialcenter, setmesialcenter] = useState(false);
  const [center, setcenter] = useState(false);
  const [smooth, setSmooth] = useState(false);
  const [vectorOfAxis, setVectorOfAxis] = useState(axis);
  const [grooves, setGrooves] = useState([]);
  const [inner_distance, setInner_distance] = useState(null);
  const primarycolor = "#213F99";

  const [distanceView, setDistanceView] = useState(false);
  const [addsub, setaddsub] = useState(1);
  const [adjList, setAdjList] = useState(null);
  const [brushingRadius, setBrushingRadius] = useState(2);

  const [deformation, setDeformation] = useState(false);
  const [deformationRadius, setDeformationRadius] = useState(3);

  // const brushingRadius = useRef(2);
  const [brushingStrength, setBrushingStrength] = useState(0.02);
  const [smootheningRadius, setSmootheningRadius] = useState(2);

  const [smootheningStrength, setSmootheningStrength] = useState(0.5);
  const color = useRef('#ffffff');
  const [renderer, setRenderer] = useState(null);
  const [camera, setCamera] = useState(null);
  const [controls, setControls] = useState(null);
  const[trans_control, setTransControl] = useState(null);
  const [scene, setScene] = useState(null);
  const [allowed_vertices, setAllowedVertices] = useState(null);
  const [index_allowed_map, setIndexMap] = useState([])
  const [mapped_crown_indices, setMappedIncides] = useState([])
  const [sliderData, setSliderData] = useState([
    { name: 'Prep', opacity: 1, visible: prepView },
    { name: 'Anta', opacity: 1, visible: antaView },
    // Add Crown files to the sliderData array
    ...crown.map((crownFile, index) => ({
      name: `Crown_${index}`,  // You can customize the naming convention for each Crown file
      opacity: 1,
      visible: true,
    })),
  ]);
  const [marginManipulationTool, setMarginManipulationTool] = useState(false);
  const [undoStack, setUndoStack] = useState([]);

  const splineMaterial = new THREE.MeshStandardMaterial({
      color: !marginManipulationTool ? 0xff0000 : 0x0096FF, // Bright orange for a vibrant appearance
      metalness: 0.5,  // Increase metalness for a slight shine
      roughness: 0.3,  // Moderate roughness for some light reflection
      emissive: !marginManipulationTool ? 0xff0000 : 0x0096FF, // Bright yellow emissive for contrast
      emissiveIntensity: 0.7 // Higher intensity for a more noticeable glow
    });


  const curveRef = useRef(null);
  const raycaster = new THREE.Raycaster();
  const mouseRef = useRef(new THREE.Vector2());
  let isDragging = useRef(false);
  const selectedPoints = useRef([]); // To store the points for the line
  const [meshRef, setMeshRef] = useState(null);
  const [marginPCD, setMarginPCD] = useState(marginCurve);

  const [rotatetool, setRotatetool] = useState(false);
  const [ translatetool, setTranslatetool] = useState(false);
  const [scaletool, setScaletool] = useState(false);

  // let IdxSet = new Set();

  const handleBrushingRadiusChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("radius", event.target.value);
      setBrushingRadius(parseFloat(event.target.value));
      // brushingRadius.current = event.target.value;
      console.log(brushingRadius);
    } else {
      console.error('Event target or its value is null/undefined');
    }
  };
  const handleSmootheningStrengthChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("smooth_strength", event.target.value);
      setSmootheningStrength(event.target.value);
    } else {
      console.error('Event target or its value is null/undefined');
  }}

  // Function to handle brushing strength change
  const handleBrushingStrengthChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("strength", event.target.value);
      setBrushingStrength(parseFloat(event.target.value));
    } else {
      console.error('Event target or its value is null/undefined');
    }
  };

  const handleSmootheningRadiusChange = (event) => {
    if (event.target && event.target.value !== undefined) {
      console.log("smooth_radius", event.target.value);
      setSmootheningRadius(event.target.value);
    } else {
      console.error('Event target or its value is null/undefined');
    }
  }

  const removeMesh = (name) => {
      const mesh = scene.getObjectByName(name);
      if (mesh) {
        if (mesh.geometry) mesh.geometry.dispose();
        if (mesh.material) mesh.material.dispose();
        scene.remove(mesh);
      }
    };

  const containerRef = useRef(null);
  const [threeJSManager, setThreeJSManager] = useState(null);

  // const [object, setObject] = useState(null);
  const [kdtree, setKdtree] = useState(null);
  // const [positions, setPositions] = useState(null);

  useEffect(() => {
      if(threeJSManager && scene){
        let newSplinecurve  
        let marginRadius = 0.04
        
        const updateTubeGeometry = () => {
          if (selectedPoints.current.length >= 2) {
            if(scene.getObjectByName('redSpline')){
              removeMesh('redSpline');
            }
            const beizer = createCubicBezierSegments(selectedPoints.current);
            newSplinecurve = new THREE.CurvePath();
            beizer.forEach((curve) => newSplinecurve.add(curve));
            const tubeGeometry = new THREE.TubeGeometry(newSplinecurve, selectedPoints.current.length * 20, 0.03, 8, false);
            const splineMaterial1 = new THREE.MeshBasicMaterial({ color: 0x000000 });
            const tubeMesh = new THREE.Mesh(tubeGeometry, splineMaterial1);
            tubeMesh.name = 'redSpline';
            scene.add(tubeMesh);
          }
        };
  
        const closedCurve = (pointArray, numControlPoints) => {
          const controlPoints = [];
          const stepSize = (pointArray.length - 1) / (numControlPoints - 1);
      
          for (let i = 0; i < numControlPoints; i++) {
              const index = i * stepSize;
              const lowIndex = Math.min(Math.floor(index), pointArray.length - 1);
              const highIndex = Math.min(Math.ceil(index), pointArray.length - 1);
      
              if (lowIndex === highIndex) {
                  controlPoints.push(pointArray[lowIndex]);
              } else {
                  // Interpolate between lowIndex and highIndex
                  const t = Math.max(0, Math.min(1, index - lowIndex));
                  const interpolated = pointArray[lowIndex].clone().lerp(pointArray[highIndex], t);
                  controlPoints.push(interpolated);
              }
          }
      
          // Close the curve by adding the first point at the end
          controlPoints.push(controlPoints[0].clone());
      
          return controlPoints;
      };
      
      
  
        const update = (maincurve, newcurve) => {
          if (!newcurve) return;
      
          // Get evenly spaced points from the entire main curve
          const getEvenlySpacedPoints = (curve, numPoints) => {
              const points = [];
              for (let i = 0; i <= numPoints; i++) {
                  const t = i / numPoints; // Normalized interval
                  points.push(curve.getPointAt(t));
              }
              return points;
          };
      
          const numMainPoints = 300; // Adjust as needed for smoothness
          const maincurvePoints = getEvenlySpacedPoints(maincurve, numMainPoints);
      
          const start = newcurve.getPoint(0);
          const end = newcurve.getPoint(1);
      
          let startind = 0,
              endind = 0;
          let minstartDist = Infinity,
              minendDist = Infinity;
      
          // Find the closest indices for the start and end points of the new curve
          maincurvePoints.forEach((point, index) => {
              const startDist = point.distanceTo(start);
              const endDist = point.distanceTo(end);
              if (startDist < minstartDist) {
                  minstartDist = startDist;
                  startind = index;
              }
              if (endDist < minendDist) {
                  minendDist = endDist;
                  endind = index;
              }
          });
      
          // Reverse the curve if start index is greater than end index
          const reverseCurve = (curve) => {
            // Get evenly spaced points and reverse them
            const reversedPoints = getEvenlySpacedPoints(curve, 150).reverse();
        
            // Create new curve segments from the reversed points
            const reversedSegments = createCubicBezierSegments(reversedPoints);
        
            // Construct a new CurvePath from the reversed segments
            const reversedCurve = new THREE.CurvePath();
            reversedSegments.forEach((segment) => reversedCurve.add(segment));
        
            return reversedCurve;
        };
        
      
          if (startind > endind) {
              [startind, endind] = [endind, startind];
              newcurve = reverseCurve(newcurve);
          }
          const smoothfactor = 1;
          startind = Math.max(0, startind - smoothfactor);
          endind = Math.min(maincurvePoints.length - 1, endind + smoothfactor);
      
          // Determine if first segment is smaller
          let mid = startind + Math.floor((endind - startind) / 2);
          const firstsegdist = newcurve.getPoint(0.5).distanceTo(maincurvePoints[mid]);
          const lastsegdist = newcurve.getPoint(0.5).distanceTo(maincurvePoints[0]);
          const isFirstSegmentSmaller = firstsegdist < lastsegdist;
          const numPoint = isFirstSegmentSmaller ? endind - startind : maincurve.curves.length - (endind - startind);
          const newcurvePoints = getEvenlySpacedPoints(newcurve, numPoint);
  
          const isSmaller = reverseCurve(newcurve)
          let isSmallerPoints = getEvenlySpacedPoints(isSmaller,numPoint);
      
          // Create new curve by replacing points in the smaller segment
          let newmaincurve = isFirstSegmentSmaller
              ? [
                    ...maincurvePoints.slice(0, startind),
                    ...newcurvePoints,
                    ...maincurvePoints.slice(endind),
                ]
              : [
                    ...isSmallerPoints,
                    ...maincurvePoints.slice(startind+(2*smoothfactor), endind-(2*smoothfactor)),
                    
                ];
  
            newmaincurve = closedCurve(newmaincurve, 300);
          
          return newmaincurve;
      };
      
      
        
      const onMouseDown = (event) => {
        if(event.button === 2){
          return;
        }
        event.preventDefault();
        isDragging.current = true;
        selectedPoints.current = []; // Reset points for a new spline
      };
  
      // Mouse move event to add points while dragging
      const onMouseMove = (event) => {
        if (event.button === 0 && isDragging.current) {
          // Update mouse vector with normalized device coordinates
          const rect = renderer.domElement.getBoundingClientRect();
          mouseRef.current.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
          mouseRef.current.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      
          // Cast ray and check for intersections with mesh
          raycaster.setFromCamera(mouseRef.current, camera);
          const prepMesh = scene.getObjectByName('prep');
          const intersects = raycaster.intersectObject(prepMesh);
      
          if (intersects.length > 0) {
            const point = intersects[0].point.clone();
            selectedPoints.current.push(point);
            updateTubeGeometry();
          }
        }
      };
      
      const onMouseUp = () => {
        try {
          // Ensure a valid newSplinecurve exists before proceeding
          if (!newSplinecurve || selectedPoints.current.length < 4) {
            return;
          }
      
          const newmaincurve = update(curveRef.current, newSplinecurve);
  
          // newmaincurve.forEach((point) => {
          //   const sphereGeometry = new THREE.SphereGeometry(0.06, 32, 32);
          //   const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
          //   const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
          //   sphere.position.copy(point);
          //   scene.add(sphere);
          // })
          if (!newmaincurve) return;
      
          const segments = createCubicBezierSegments(newmaincurve);
          const pnts = combineBezierPoints(segments);
          const curv = new THREE.CurvePath();
          segments.forEach((curve) => curv.add(curve));
          curveRef.current = curv
      
          // Remove old splines and add the updated one
          const orignalSpline = scene.getObjectByName('blackSpline');
          if (orignalSpline) {
            removeMesh('blackSpline');
          }
      
          const newSpline = scene.getObjectByName('redSpline');
          if (newSpline) {
            removeMesh('redSpline');
          }
      
          const tube = new THREE.TubeGeometry(curv, 1000, 0.04, 1000, false);
          const newCombinedSpline = new THREE.Mesh(tube, splineMaterial);
          newCombinedSpline.name = 'blackSpline';
          scene.add(newCombinedSpline);
          isDragging.current = false;
        } catch (error) {
          isDragging.current = false;
          console.error('Error updating spline:', error);
        }
        finally{
          isDragging.current = false;
        }
      };
  
      const createCubicBezierSegments = (points) => {
        const curves = [];
    for (let i = 0; i < points.length - 1; i++) {
      const p0 = points[i];
      const p3 = points[i + 1];
      
      // Tangent alignment for smooth transitions
      const tangent = p3.clone().sub(p0).multiplyScalar(0.33); 
      const p1 = p0.clone().add(tangent);
      const p2 = p3.clone().sub(tangent);
  
      curves.push(new THREE.CubicBezierCurve3(p0, p1, p2, p3));
    }
    return curves;
      };
  
      const combineBezierPoints = (bezierCurves, divisions = 25) => {
        const combinedPoints = [];
        bezierCurves.forEach((curve) => {
          const points = curve.getPoints(divisions);
          combinedPoints.push(...points);
        });
        return combinedPoints;
      };
  
      
  
      if (threeJSManager && marginPCD && !curveRef.current) {
        const pcdLoader = new PCDLoader();
        const pcdBlob = new Blob([marginPCD], { type: 'application/octet-stream' });
        const pcdUrl = URL.createObjectURL(pcdBlob);
        
        pcdLoader.load(pcdUrl, (points) => {
          const positions = points.geometry.attributes.position.array;
      
          // Sample control points evenly
          const generateControlPoints = (positions, numControlPoints) => {
            // Convert flat position array into THREE.Vector3 objects
            const pointArray = [];
            for (let i = 0; i < positions.length; i += 3) {
                pointArray.push(new THREE.Vector3(positions[i], positions[i + 1], positions[i + 2]));
            }
        
            // Ensure there are enough points
            if (pointArray.length <= numControlPoints) {
                console.warn('Insufficient points. Returning available points.');
                return pointArray.slice(); // Return all points if less than requested
            }
        
            // Calculate step size for even distribution
            const controlPoints = [];
            const stepSize = (pointArray.length - 1) / (numControlPoints - 1);
        
            for (let i = 0; i < numControlPoints; i++) {
                const index = i * stepSize;
                const lowIndex = Math.floor(index);
                const highIndex = Math.ceil(index);
        
                if (lowIndex === highIndex) {
                    controlPoints.push(pointArray[lowIndex]);
                } else {
                    // Interpolate between lowIndex and highIndex
                    const t = index - lowIndex;
                    const interpolated = pointArray[lowIndex].clone().lerp(pointArray[highIndex], t);
                    controlPoints.push(interpolated);
                }
            }
        
            return controlPoints;
        };
        
        const numControlPoints = 400;
        const controlPoints = generateControlPoints(positions, numControlPoints);
        
        
      
          // Generate cubic Bézier segments
          const bezierSegments = createCubicBezierSegments(controlPoints);
      
          // Create a continuous geometry using CurvePath
          const fullCurve = new THREE.CurvePath();
          bezierSegments.forEach((curve) => fullCurve.add(curve));
      
          curveRef.current = fullCurve;
      
          // Generate TubeGeometry
          const tube = new THREE.TubeGeometry(fullCurve, 1000, marginRadius, 100, false); // Set 'closed' to true
          const originalMesh = new THREE.Mesh(tube, splineMaterial);
          originalMesh.name = 'blackSpline';
      
          scene.add(originalMesh);
          // controlPoints.forEach((point) => {
          //   const sphereGeometry = new THREE.SphereGeometry(0.07, 32, 32);
          //   const sphereMaterial = new THREE.MeshBasicMaterial({ color: 0x000000 });
          //   const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);
          //   sphere.position.copy(point);
          //   scene.add(sphere);
          // });
        });
      }
      
  if(!marginManipulationTool){
        controls.noPan = false;
        controls.enablePan = true;
  }
      if(marginManipulationTool){
        controls.noPan = true;
        controls.enablePan = false;

        const marginSpline=scene.getObjectByName('blackSpline');
        if(marginSpline){
          marginSpline.material.color.set(0x0096FF);
          marginSpline.material.emissive.set(0x0096FF); 
          marginSpline.material.transparent = true;
        }
        renderer.domElement.addEventListener('mousedown', onMouseDown);
      renderer.domElement.addEventListener('mousemove', onMouseMove);
      renderer.domElement.addEventListener('mouseup', onMouseUp);
    }
  
      return () => {
        if(marginManipulationTool){
  
          const marginSpline=scene.getObjectByName('blackSpline');
        if(marginSpline){
          marginSpline.material.color.set(0xff0000);
          marginSpline.material.emissive.set(0xff0000); 
          marginSpline.material.transparent = true;
        }
  
          renderer.domElement.removeEventListener('mousedown', onMouseDown);
        renderer.domElement.removeEventListener('mousemove', onMouseMove);
        renderer.domElement.removeEventListener('mouseup', onMouseUp);
  }
      }}
    }, [threeJSManager,scene,meshRef,marginManipulationTool,marginPCD]);
  
    useEffect(() => {
      
      if (!threeJSManager || !scene || !camera || !renderer || deformation) return;
      let isSmoothing = false;
      // const raycaster = new THREE.Raycaster();
      const mouse = new THREE.Vector2();
      const object = scene.getObjectByName('crown_0');
      // const ring = scene.getObjectByName("brushing_ring");
      // let ringGeometry = null;

      // // if(scene.getObjectByName('brushing_ring')) removeMesh("brushing_ring");
      // if(brushing){
      //   ringGeometry = new THREE.RingGeometry(brushingRadius - brushingRadius/20, brushingRadius, 32);
      // }
      // else if(smooth){
      //   ringGeometry = new THREE.RingGeometry(smootheningRadius - smootheningRadius/20, smootheningRadius, 32);
      // }
      // else{
      //   ringGeometry = new THREE.RingGeometry(brushingRadius - brushingRadius/20, brushingRadius, 32);
      // }
      // const ringMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide, depthTest: false, transparent: true,
      //   opacity: 1.0 });
      // const ring = new THREE.Mesh(ringGeometry, ringMaterial);
      // ring.renderOrder = 10;
      // console.log(ring);

      let meshCenter = new THREE.Vector3(0, 0, 0);
      if(object && object.geometry){
        // object.renderOrder = 1
        meshCenter = object.geometry.boundingSphere.center;

        // ring.name = "brushing_ring";
        // scene.add(ring);
        // ring.visible = false
      }
      const domElement = renderer.domElement;
    
      // Event Handlers
      const startSmoothing = (event) => {
        if(object && object.geometry){
        setUndoStack((prevStack) => [...prevStack, object.geometry.clone()]);
        }
        isSmoothing = true;
        updateSmoothing(event);
      };
    
      const updateSmoothing = (event) => {
        if (!isSmoothing) return;
    
        if (event.button === 0 && event.ctrlKey && (brushing || smooth)) {
          controls.noPan = true;
          controls.enablePan = false;
    
          // Calculate mouse position in normalized device coordinates
          const rect = domElement.getBoundingClientRect();
          mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
          mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
          // Update the picking ray with the camera and mouse position
          raycaster.setFromCamera(mouse, camera);
    
          // Find intersecting objects
          const intersects = raycaster.intersectObjects(scene.children);
          if (intersects.length > 0) {

            const intersectionPoint = intersects[0].point;
            const intersectNormal = intersects[0].face.normal;
    
            if (brushing && kdtree) {
              // console.log(object.geometry);
              // deformMesh(addsub, intersectionPoint, intersectNormal, object.geometry);
              const directionVector = new THREE.Vector3().subVectors(intersectionPoint, meshCenter);

              // Step 2: Normalize the vectors
              const normalizedNormal = intersectNormal.clone().normalize();
              const normalizedDirection = directionVector.clone().normalize();

              // Step 3: Compute the dot product
              const dotProduct = normalizedNormal.dot(normalizedDirection);

              // Step 4: Calculate angle in degrees
              const angleRadians = Math.acos(dotProduct);
              const angleDegrees = THREE.MathUtils.radToDeg(angleRadians);
              if (angleDegrees >= -90 && angleDegrees <= 90) {
                // console.log(angleDegrees, meshCenter);
                addFace(addsub, intersectionPoint, intersectNormal, kdtree, object.geometry);
              }
            }
            if (smooth) {
              smoothenFrontend(intersectionPoint, kdtree, object.geometry);
            }
            
          }
        } else if(!marginManipulationTool) {
          controls.noPan = false;
          controls.enablePan = true;
        }
      };
    
      const onMouseMove = (event) => {
        const rect = domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children);
    
        const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
        if (crownIntersect && (brushing || smooth)) {
          const currentHoverPoint = crownIntersect.point;
          // const currentHoverNormal = crownIntersect.face.normal;
          if (object && object.material) {
            if(brushing){
              object.material.uniforms.brushradius.value = parseFloat(brushingRadius);
            }
            else if(smooth){
              object.material.uniforms.brushradius.value = parseFloat(smootheningRadius);
            }
            // const offset = currentHoverNormal.clone().multiplyScalar(0.5); // Small offset to prevent collision
            // ring.position.copy(currentHoverPoint);
            // const axis = new THREE.Vector3(0, 0, 1); // Default orientation of the ring
            // const quaternion = new THREE.Quaternion().setFromUnitVectors(axis, currentHoverNormal);
            // ring.setRotationFromQuaternion(quaternion);
            // ring.visible = true;
            object.material.uniforms.uHoverPosition.value.copy(currentHoverPoint);
          }
        } else if (object && object.material) {
          object.material.uniforms.uHoverPosition.value.set(-1, -1, -1);
          // ring.visible = false;
          if(brushing){
            object.material.uniforms.brushradius.value = parseFloat(brushingRadius);
          }
          else if(smooth){
            object.material.uniforms.brushradius.value = parseFloat(smootheningRadius);
          }
        }
      };
    
    //   function getVertexNeighbors(geometry, vertexIndex) {
    //     // const geometry = mesh.geometry;
    
    //     if (!geometry.index) {
    //         console.error("Geometry must be indexed to find neighbors.");
    //         return [];
    //     }
    
    //     const indices = geometry.index.array; // Array of vertex indices forming faces
    //     const neighbors = new Set();
    
    //     // Iterate through all faces
    //     for (let i = 0; i < indices.length; i += 3) {
    //         const a = indices[i];
    //         const b = indices[i + 1];
    //         const c = indices[i + 2];
    
    //         // Check if the current face contains the vertex
    //         if (a === vertexIndex || b === vertexIndex || c === vertexIndex) {
    //             // Add the other two vertices to the neighbors set
    //             if (a !== vertexIndex) neighbors.add(a);
    //             if (b !== vertexIndex) neighbors.add(b);
    //             if (c !== vertexIndex) neighbors.add(c);
    //         }
    //     }
    
    //     return Array.from(neighbors); // Convert the Set to an Array
    // }
    
    // function smoothening(){
    //     const positions = object.geometry.attributes.position.array;
    //     for(const i of IdxSet){
    //         const indices = getVertexNeighbors(object.geometry, i);
    //         let avg_pos = new THREE.Vector3(0,0,0);
    //         let count = indices.length;
    //         for(let j = 0; j < indices.length; j++){
    //             avg_pos.x += positions[indices[j]*3];
    //             avg_pos.y += positions[indices[j]*3 + 1];
    //             avg_pos.z += positions[indices[j]*3 + 2];
    //         }
    //         // Assuming positions is a Float32Array or similar
    //         const currentPosition = new THREE.Vector3(
    //           positions[i * 3],
    //           positions[i * 3 + 1],
    //           positions[i * 3 + 2]
    //         );

    //         const targetPosition = new THREE.Vector3(
    //           avg_pos.x / count,
    //           avg_pos.y / count,
    //           avg_pos.z / count
    //         );

    //         // Interpolate between currentPosition and targetPosition
    //         const lerpFactor = 0.1; // Adjust this value for smoother/slower transitions
    //         currentPosition.lerp(targetPosition, lerpFactor);

    //         // Update positions array with interpolated values
    //         positions[i * 3] = currentPosition.x;
    //         positions[i * 3 + 1] = currentPosition.y;
    //         positions[i * 3 + 2] = currentPosition.z;

    //     }
    //     object.geometry.attributes.position.needsUpdate = true;
    //     object.geometry.computeVertexNormals();
    // }
    
      function onMouseUp(event) {
        // smoothening
        // if (!IdxSet.size) return;
        if(!(brushing || smooth) || (!object || !object.geometry)) return;
      
        // requestAnimationFrame(() => {
        //   const startTime = performance.now();
          
        //   // if (brushingRadius < 1) {
        //   //   smoothening();
        //   // }
    
        //   // Batch geometry updates
          const geometries = U.parseGeometry(object.geometry);
          const vertices = geometries[0];
          
          let allowed_crown_vertices = [];
          let indexMap = [];
          for (let i =0 ; i < mapped_crown_indices.length; i++ ){
            allowed_crown_vertices.push(vertices[mapped_crown_indices[i]]);
            indexMap.push(mapped_crown_indices[i]);
          }
          setIndexMap(indexMap);
          setKdtree(new KdTree(allowed_crown_vertices));
          // Batch normal computations
          object.geometry.computeVertexNormals();

          // Push the current geometry to the undo stack
          // IdxSet.clear();
          // console.log(`Smoothening: ${performance.now() - startTime}ms`);
        // });
      }
    
      const brushingRadiusSlider = document.getElementById('brushingradius');
      const brushingStrengthSlider = document.getElementById('brushingstrength');
      const smootheningRadiusSlider = document.getElementById('smootheningradius');
      const smootheningStrengthSlider = document.getElementById('smootheningstrength');
    

      
    
      // Add Event Listeners
      domElement.addEventListener('mousemove', onMouseMove);
      domElement.addEventListener('mousedown', startSmoothing);
      domElement.addEventListener('mousemove', updateSmoothing);
      domElement.addEventListener('mouseup', onMouseUp);
    
      brushingRadiusSlider?.addEventListener('input', handleBrushingRadiusChange);
      brushingStrengthSlider?.addEventListener('input', handleBrushingStrengthChange);
      smootheningRadiusSlider?.addEventListener('input', handleSmootheningRadiusChange);
      smootheningStrengthSlider?.addEventListener('input', handleSmootheningStrengthChange);
    
      // Clean up Event Listeners on unmount
      return () => {
        domElement.removeEventListener('mousedown', startSmoothing);
        domElement.removeEventListener('mousemove', onMouseMove);
        domElement.removeEventListener('mousemove', updateSmoothing);
        domElement.removeEventListener('mouseup', onMouseUp);
        
        // removeMesh('brushing_ring');
    
        brushingRadiusSlider?.removeEventListener('input', handleBrushingRadiusChange);
        brushingStrengthSlider?.removeEventListener('input', handleBrushingStrengthChange);
        smootheningRadiusSlider?.removeEventListener('input', handleSmootheningRadiusChange);
        smootheningStrengthSlider?.removeEventListener('input', handleSmootheningStrengthChange);
      };
    }, [threeJSManager, scene, camera, renderer, brushing,marginManipulationTool ,smooth, brushingRadius, addsub, kdtree, brushingStrength, smootheningRadius, smootheningStrength]);
    
    useEffect(() => {
      if(!threeJSManager || !scene || !camera || !renderer || !deformation) return;
      // let ringGeometry = null;
      const object = scene.getObjectByName('crown_0');
      let old_geometry = null;
      // deformationRadius = brushingRadius;
      console.log("Deformation Radius", deformationRadius);
      // let ring = scene.getObjectByName('brushing_ring');
      // const ringGeometry = new THREE.RingGeometry(deformationRadius - deformationRadius/20, deformationRadius, 32);
      // const ringMaterial = new THREE.MeshBasicMaterial({ color: 0xff0000, side: THREE.DoubleSide, depthTest: false, transparent: true,
      //   opacity: 1.0 });
      // const ring = new THREE.Mesh(ringGeometry, ringMaterial);

      if(object && object.geometry){
        old_geometry = object.geometry.clone();
        // ring.renderOrder = 10;
        // ring.name = "brushing_ring2";
        // scene.add(ring);
        // ring.visible = false;
      }
      else{
        return;
      }
      let isDragging = false;
      let startPoint = null;
      let intersectedPoint = null;
      let normal2D = null;
      const mouse = new THREE.Vector2();
      const domElement = renderer.domElement;
    
      function deformMesh(intersectPoint, normal, deformInt){
          const startTime1 = performance.now();
          const positions = object.geometry.attributes.position.array;
          const startTime2 = performance.now(); 
          const brushRadiusSquared = deformationRadius*deformationRadius;
    
          deformInt = Math.min(deformInt, 1);
    
          // let count = 0;
    
          // Bezier control points for falloff
          const p0 = 1.0;  
          const p1 = 1.0;  
          const p2 = 1.0; 
          const p3 = 0.0; 
          const p4 = 0.0;
          const p5 = 0.0;
    
    
          // KNN approach for making subset of positions
    
          kdtree.rnn([intersectPoint.x,intersectPoint.y,intersectPoint.z], deformationRadius, function(j) {
              let i = index_allowed_map[j];
              const dx = positions[i*3] - intersectPoint.x;
              const dy = positions[i*3 + 1] - intersectPoint.y;
              const dz = positions[i*3 + 2] - intersectPoint.z;
    
    
              const distanceSquared = dx * dx + dy * dy + dz * dz;
    
              const vertex = new THREE.Vector3(positions[i*3], positions[i*3 + 1], positions[i*3 + 2]);
    
              // Deform vertices within brush radius
              if (distanceSquared < brushRadiusSquared) {
                  const distance = Math.sqrt(distanceSquared);
                  const t = distance / deformationRadius; 
                  const falloff = pentBezier(t, p0, p1, p2, p3, p4, p5);
                  vertex.addScaledVector(normal, falloff * deformInt);
                  positions[i*3] = vertex.x;
                  positions[i*3 + 1] = vertex.y;
                  positions[i*3 + 2] = vertex.z;
              }
          })
          const startTime3 = performance.now();
          object.geometry.attributes.position.needsUpdate = true;
          // mesh.geometry.computeVertexNormals();
          const startTime4 = performance.now();
          console.log(startTime4-startTime3, startTime3-startTime2, startTime2-startTime1);
      }
    
      function pentBezier(t, p0, p1, p2, p3, p4, p5){
          const u = 1-t;
          return (u * u * u * u * u * p0) +
                  (5 * u * u * u * u * t * p1) +
                  (10 * u * u * u * t * t * p2) +
                  (10 * u * u * t * t * t * p3) +
                  (5 * u * t * t * t * t * p4) +
                  (t * t * t * t * t * p5);
      }
    
    
      function onMouseDown(event) {
        const rect = domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      
          raycaster.setFromCamera(mouse, camera);
          const intersects = raycaster.intersectObjects(scene.children);
          const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
      
          if (crownIntersect && event.button === 0 && event.ctrlKey) {
              if(!isDragging){
                console.log("Deformation Started");
                isDragging = true;
                intersectedPoint = crownIntersect;
                old_geometry = object.geometry.clone();
                startPoint = new THREE.Vector2(event.clientX, event.clientY);
                const normal = intersectedPoint.face.normal.clone();
                // normal.transformDirection(object.matrixWorld); // Transform to world space
                const normalScreen = normal.clone().transformDirection(object.modelViewMatrix).transformDirection(camera.projectionMatrix);
                normal2D = new THREE.Vector2(normalScreen.x, -normalScreen.y);
              }
          }
      }
      
      function onMouseMove(event) {
          if(isDragging && event.button === 0 && event.ctrlKey){ 
            controls.noPan = true;
            controls.enablePan = false;
            const endPoint = new THREE.Vector2(event.clientX, event.clientY);
            const dragVector = new THREE.Vector2().subVectors(endPoint, startPoint); 
            const normal = intersectedPoint.face.normal.clone();   
    
            // Map normal to 2D space    
            console.log("Normal", normal2D);
            console.log("Drag Vector", dragVector);
            console.log("Angle", (dragVector.angle() - normal2D.angle())* (180/Math.PI));
    
    
            // Calculate angle between drag vector and mapped normal
            const angle = dragVector.angle() - normal2D.angle();
            const axis = new THREE.Vector3().crossVectors(normal, new THREE.Vector3(0, 0, 1)).normalize();
            if(normal2D.x < 0) normal.applyAxisAngle(axis, angle);
            if(normal2D.x >= 0) normal.applyAxisAngle(axis, -angle);
    
            // Calculate deformation strength
            const deformationStrength = 0.03* dragVector.length() * Math.abs(Math.cos(angle));
            console.log(deformationStrength);
    
            object.geometry = old_geometry.clone();
    
            deformMesh(intersectedPoint.point, normal, deformationStrength);
          }  
      }
      // function RingVisualization(event){
      //   if (isDragging) return;
      //   const rect = domElement.getBoundingClientRect();
      //     mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      //     mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
      
      //     raycaster.setFromCamera(mouse, camera);
      //     const intersects = raycaster.intersectObjects(scene.children);
      //     const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
      
      //     if (crownIntersect && ring && ring.geometry) {
      //         const point = crownIntersect.point;
      //         const normal = crownIntersect.face.normal;
      //         // normal.transformDirection(object.matrixWorld); // Transform to world space
          
      //         // Position the hover ring
      //         ring.position.copy(point);
      //         ring.lookAt(point.clone().add(normal));
      //         ring.visible = true;
      //         // console.log("Ring Visible", ring, scene);  
      //     } else {
      //         ring.visible = false;
      //     }
      // }
      
      function RingVisualization(event){
        if (isDragging) return;
        const rect = domElement.getBoundingClientRect();
        mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
        mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;
    
        raycaster.setFromCamera(mouse, camera);
        const intersects = raycaster.intersectObjects(scene.children);
    
        const crownIntersect = intersects.find(intersect => intersect.object.name === 'crown_0');
        if (crownIntersect) {
          const currentHoverPoint = crownIntersect.point;
          if (object && object.material) {
            object.material.uniforms.brushradius.value = parseFloat(deformationRadius);
            object.material.uniforms.uHoverPosition.value.copy(currentHoverPoint);
          }
        } else if (object && object.material) {
          object.material.uniforms.uHoverPosition.value.set(-1, -1, -1);
          object.material.uniforms.brushradius.value = parseFloat(deformationRadius);
        }
      };

      function onMouseUp(event) {
          if (!isDragging || !startPoint) return;
          isDragging = false;
          startPoint = null;
          old_geometry = object.geometry.clone();
          object.geometry.attributes.position.needsUpdate = true;
          const geometries = U.parseGeometry(object.geometry);
          const vertices = geometries[0];
          
          let allowed_crown_vertices = [];
          let indexMap = [];
          for (let i =0 ; i < mapped_crown_indices.length; i ++ ){
            allowed_crown_vertices.push(vertices[mapped_crown_indices[i]]);
            indexMap.push(mapped_crown_indices[i]);
          }
          setIndexMap(indexMap);
          setKdtree(new KdTree(allowed_crown_vertices));
          // Batch normal computations
          object.geometry.computeVertexNormals();
          controls.noPan = false;
          controls.enablePan = true;
          return;
      }   
      
      const handleDeformationRadiusChange = (event) => {
        if (event.target && event.target.value !== undefined) {
          console.log("smooth_radius", event.target.value);
          setDeformationRadius(event.target.value);
        } else {
          console.error('Event target or its value is null/undefined');
        }
      }

      domElement.addEventListener('mousedown', onMouseDown);
      domElement.addEventListener('mousemove', onMouseMove);
      domElement.addEventListener('mousemove', RingVisualization);
      domElement.addEventListener('mouseup', onMouseUp);

      const DeformationRadiusSlider = document.getElementById('deformationradius');
      DeformationRadiusSlider?.addEventListener('input', handleDeformationRadiusChange);

      return () => {
        domElement.removeEventListener('mousedown', onMouseDown);
        domElement.removeEventListener('mousemove', onMouseMove);
        domElement.removeEventListener('mouseup', onMouseUp);
        domElement.removeEventListener('mousemove', RingVisualization);
        DeformationRadiusSlider?.removeEventListener('input', handleDeformationRadiusChange);
        
        // removeMesh('brushing_ring2');
      }

    }, [threeJSManager, scene, camera, renderer,deformation,deformationRadius]);

  useEffect(() => {
    if (containerRef.current) {
      const manager = new ThreeJSManager(
        containerRef.current.id,
        initialCameraPosition, color, sliderData, false, crownarray, file1, file2, crown, prepView, antaView, scene, camera, controls
      );
      manager.initRenderer();
      manager.initScene();
      manager.initCamera();
      manager.initControls();
      manager.animate();
      manager.loadAllSTLs();
      setScene(manager.scene);
      setRenderer(manager.renderer);
      setCamera(manager.camera);
      setControls(manager.controls);
      setThreeJSManager(manager);
      // const mpp = U.map_to_be_removed(removed_indices)
      setAllowedPoints(allowed_points);
      
      return () => {
        manager.cleanup();
      };
    }


  }, []);
  const processSceneData = () => {
    // setffd(new FFD());
    
  
    const handleCrownLoading = () => {
      if (scene.getObjectByName("crown_0")) {
        const object = scene.getObjectByName("crown_0");
        const geometry = object.geometry;
        const geometries = U.parseGeometry(geometry, true);
        console.log("adjacent set");
        const faces = geometries[1];
        const adj = U.build_adjacency(geometry, faces);
        if (!adjList) {
          setAdjList(adj);
          console.log(adj); // Log adj instead of adjList to see immediate value
        }
      }
    };
  
    if (scene) {
      const object = scene.getObjectByName("crown_0");
      if (object && object.geometry) {

        const geometries = U.parseGeometry(object.geometry);
        const vertices = geometries[0];

      let crownTree = new KdTree(vertices);
        let mapped_indices = []
        for (let i = 0; i < allowed_points_list.length; i++){
            mapped_indices.push(crownTree.knn(allowed_points_list[i], 1)[0])
        }
        setMappedIncides(mapped_indices)
        let indexMap = []
        let allowed_crown_vertices = []
        for (let i =0 ; i < mapped_indices.length; i ++ ){
          allowed_crown_vertices.push(vertices[mapped_indices[i]])
          indexMap.push(mapped_indices[i])
        }
        setIndexMap(indexMap)
        setKdtree(new KdTree(allowed_crown_vertices))
        console.log("I am here too");

      }
    }
  
    if (threeJSManager) {
      threeJSManager.loadcrowns(crownarray, handleCrownLoading);
    }
  };
  useEffect(() => {
    processSceneData();
  }, [threeJSManager,adjList ,crownarray, vectorOfAxis]);

  useEffect(() => {
    const createCubicBezierSegments = (points) => {
          const curves = [];
      for (let i = 0; i < points.length - 1; i++) {
        const p0 = points[i];
        const p3 = points[i + 1];
        
        // Tangent alignment for smooth transitions
        const tangent = p3.clone().sub(p0).multiplyScalar(0.33); 
        const p1 = p0.clone().add(tangent);
        const p2 = p3.clone().sub(tangent);
    
        curves.push(new THREE.CubicBezierCurve3(p0, p1, p2, p3));
      }
      return curves;
        };
    if (vectorOfAxis.length > 0 && scene) {
      const scaleFactor = 2;
      const colors = [0xff0000, 0x00ff00, 0x0000ff, 0xffff00, 0xff00ff]; // Array of 5 colors
      console.log(vectorOfAxis);
      // Iterate through the vectors and create lines
      for(let index=0;index<vectorOfAxis.length;index++){
        const start = vectorOfAxis[index][0] ;
        const end = vectorOfAxis[index][1];
  
        // Choose a color based on the index
        const color = colors[index % colors.length]; // Use modulo to cycle colors if needed
        const material = new THREE.LineBasicMaterial({ color });
  
        // Calculate the direction vector and scale it
        const direction = new THREE.Vector3(
          end[0] - start[0],
          end[1] - start[1],
          end[2] - start[2]
        ).multiplyScalar(scaleFactor);
  
        // Create the new scaled end point
        const scaledEnd = new THREE.Vector3(
          start[0] + direction.x,
          start[1] + direction.y,
          start[2] + direction.z
        );
  
        const points = [];
        points.push(new THREE.Vector3(start[0], start[1], start[2]));
        points.push(scaledEnd);
  
        const geometry = new THREE.BufferGeometry().setFromPoints(points);
        const line = new THREE.Line(geometry, material);
  
        scene.add(line); // Ensure `scene` is defined in your component or context
      };
    }
  }, [threeJSManager, vectorOfAxis,scene]);
  



  useEffect(() => {
    const shineSlider = document.getElementById('shine');
    const axisButton = document.getElementById('grid');
    const wireframeButton = document.getElementById('wireframe');
    const viewButton = document.getElementById('defaultview');
    const opacitySliders = document.querySelectorAll('[id^="opacitySlider-"]');
    const raycaster = new THREE.Raycaster();
    const mouse = new THREE.Vector2();

    


    const handleMouseDown = (event) => {
      if (event.button === 2) {
        return;
      }
      const rect = renderer.domElement.getBoundingClientRect();
      mouse.x = ((event.clientX - rect.left) / rect.width) * 2 - 1;
      mouse.y = -((event.clientY - rect.top) / rect.height) * 2 + 1;

      raycaster.setFromCamera(mouse, camera);
      const intersects = raycaster.intersectObjects(scene.children, true);

      if (intersects.length > 0) {
        const point = intersects[0].point;
        const selectedObject = intersects[0].object;
        if (selectedObject.name.match(/\d+$/)) {
          const match = selectedObject.name.match(/\d+$/);
          setCrownforrequest(selectedObject);
          setIndexforrequest(parseInt(match[0]));
        }
        setSelectedCoordinates(point);
        if (raycasterSphereRef.current) {
          scene.remove(raycasterSphereRef.current);
        }

        // Create a new raycaster sphere
        if (brushing || smooth) {
          return;
        }

        if (distanceView) {
          if (selectedObject.name === 'crown_0') {
            const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
            const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
            const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
            newRaycasterSphere.position.copy(point);
            raycasterSphereRef.current = newRaycasterSphere;

            // Add the new raycaster sphere to the scene
            scene.add(newRaycasterSphere);
            getDist(point);
          }
        }
        if(margin && event.button === 0){
          const raycasterMaterial = new THREE.MeshBasicMaterial({ color: 0x0000ff });
            const raycasterSphereGeometry = new THREE.SphereGeometry(0.16); // Adjust the size as needed
            const newRaycasterSphere = new THREE.Mesh(raycasterSphereGeometry, raycasterMaterial);
            newRaycasterSphere.position.copy(point);
            raycasterSphereRef.current = newRaycasterSphere;

            // Add the new raycaster sphere to the scene
            scene.add(newRaycasterSphere);
        }
      }
    }
    if (isRaycasterActive) {
      renderer.domElement.addEventListener('mousedown', handleMouseDown);
    }

    const changeShineness = () => {
      if (threeJSManager) {
        threeJSManager.changeShineness();
      }
    };

    const toggleAxes = () => {
      if (threeJSManager) {
        threeJSManager.toggleAxes();
      }
    };

    const toggleWireframe = () => {
      if (threeJSManager) {
        threeJSManager.toggleWireframe();
      }
    };

    const defaultView = () => {
      if (threeJSManager) {
        threeJSManager.defaultView();
      }
    };

    const handleOpacityChange = (index) => {
      if (threeJSManager) {
        threeJSManager.handleOpacityChange(index);
      }
    };

    if (shineSlider) {
      shineSlider.addEventListener('input', changeShineness);
    }

    if (axisButton) {
      axisButton.addEventListener('click', toggleAxes);
    }

    if (wireframeButton) {
      wireframeButton.addEventListener('click', toggleWireframe);
    }

    if (viewButton) {
      viewButton.addEventListener('click', defaultView);
    }

    opacitySliders.forEach((slider, index) => {
      slider.addEventListener('input', () => {
        handleOpacityChange(index);
      });
    });

    return () => {
      if (shineSlider) {
        shineSlider.removeEventListener('input', changeShineness);
      }

      if (axisButton) {
        axisButton.removeEventListener('click', toggleAxes);
      }

      if (wireframeButton) {
        wireframeButton.removeEventListener('click', toggleWireframe);
      }

      if (viewButton) {
        viewButton.removeEventListener('click', defaultView);
      }

      opacitySliders.forEach((slider, index) => {
        slider.removeEventListener('input', () => {
          handleOpacityChange(index);
        });
      });
    };
  }, [threeJSManager, isRaycasterActive, brushing,margin, distanceView]);



  /// Tooling useEffect

  function pentBezier(t, p0, p1, p2, p3, p4, p5){
    const u = 1-t;
    return (u * u * u * u * u * p0) +
            (5 * u * u * u * u * t * p1) +
            (10 * u * u * u * t * t * p2) +
            (10 * u * u * t * t * t * p3) +
            (5 * u * t * t * t * t * p4) +
            (t * t * t * t * t * p5);
  }

  function addFace(add, point, intnormal, kdtree, geometry) {
    if (scene) {
      const startTime1 = performance.now();
      // const geometries = U.parseGeometry(geometry);
      // const vertices = geometries[0];
      const positions = geometry.attributes.position.array;
      // const normals = geometry.attributes.normal.array; // Access vertex normals
      // const brushingRadius = object.material.uniforms.brushradius.value;
      // const brushingStrength = object.material.uniforms.brushingStrength.value;
      const startTime2 = performance.now();


      // Define the selected point and deformation parameters
      let strength = brushingStrength ? brushingStrength : 1;
      console.log(brushingRadius, brushingStrength);


      const brushingRadiusSquared = brushingRadius * brushingRadius;

      const vertex = new THREE.Vector3(0,0,0);
      // Bezier control points for falloff
      const p0 = 1.0;  
      const p1 = 1.0;  
      const p2 = 1.0; 
      const p3 = 0.0; 
      const p4 = 0.0;
      const p5 = 0.0;

      // console.log(object);
      

      kdtree.rnn([point.x,point.y,point.z], brushingRadius, function(j) {
        let i = index_allowed_map[j];
        vertex.set(positions[i*3], positions[i*3 + 1], positions[i*3 + 2]);
        
        
        const dx = vertex.x - point.x;
        const dy = vertex.y - point.y;
        const dz = vertex.z - point.z;

    
        // Compute squared distance to avoid expensive sqrt
        const distanceSquared = dx * dx + dy * dy + dz * dz;

        // const distance = vertex.distanceTo(intersectPoint);

        // Deform vertices within brush radius
        if (distanceSquared < brushingRadiusSquared) {
          // IdxSet.add(i);
          const distance = Math.sqrt(distanceSquared);
          const t = distance / brushingRadius; // Normalize distance to range [0, 1]
          // const falloff = cubicBezier(t, p0, p1, p2, p3);
          const falloff = pentBezier(t, p0, p1, p2, p3, p4, p5);
          // count+=1;
          // const distance = Math.sqrt(distanceSquared);
          // const normal = new THREE.Vector3(
          //     normals[i * 3],
          //     normals[i * 3 + 1],
          //     normals[i * 3 + 2]
          // );
          vertex.addScaledVector(intnormal, add * falloff * strength);
            // vertex.addScaledVector(intnormal, 
            //   add * strength * (1 - (3 * distanceSquared / brushingRadiusSquared) + (2 * distanceSquared * Math.sqrt(distanceSquared)/ (brushingRadius * brushingRadiusSquared)))
            // );
            positions[i*3] = vertex.x;
            positions[i*3 + 1] = vertex.y;
            positions[i*3 + 2] = vertex.z;
        }
      })
      const startTime3 = performance.now();

      // Mark the position attribute as needing an update
      geometry.attributes.position.needsUpdate = true;

      // Recompute normals and bounding sphere for smooth rendering
      geometry.computeVertexNormals();
      // geometry.computeBoundingSphere();
      const startTime4 = performance.now();

      console.log('Mesh modification completed in ', startTime4- startTime3, startTime3-startTime2, startTime2-startTime1);
      if (renderer && camera) {
        renderer.render(scene, camera);
      } else {
        console.warn('Renderer or camera not available');
      }

    } else {
      console.error('Scene not available');
    }
  };
  function smoothenFrontend(point, kdtree, geometry) {
    if (scene) {
      // const object = scene.getObjectByName('crown_0');
      // if (object && object.geometry) {
        // const geometry = object.geometry;
        // const geometries = U.parseGeometry(geometry, false)
        // const vertices = geometries[0]
        const startTime1 = performance.now();
        const position = geometry.attributes.position.array;

        // Set parameters
        let strength = 0.2; // Default strengt
        const iterations = parseInt((smootheningStrength * 10 + 0.99999));
        console.log(iterations);
        // const neighborsCount = smootheningRadius;

        // Derive start point and index
        // const T0 = new KdTree(vertices)
        // const startInd = T0.knn([point.x, point.y, point.z], 1)[0];

        // Derive only connected points to localize the effect of deformation
        // const result = U.knn_bfsbrushing(geometry,adjList ,smootheningRadius, startInd, geometries[1],vertices,false,true);
        // const connectedNeighbours = result[0];
        const adjacency = adjList;

        // Iterate
        for (let iter = 0; iter < iterations; iter++) {
        kdtree.rnn([point.x,point.y,point.z], smootheningRadius, function(j) {
          let i = index_allowed_map[j];
          const currentPos = new THREE.Vector3(
            position[i*3], position[i*3 + 1], position[i*3 + 2]
          );
          
          // const neighbors = U.nRingNeighbors(adjacency, i, 1);
          const neighbors = adjacency[i];
          const avgPos = new THREE.Vector3();
          neighbors.forEach((neighborIndex) => {
            avgPos.add(new THREE.Vector3(
              position[neighborIndex*3], position[neighborIndex*3 + 1], position[neighborIndex*3 + 2]
            ));
          });
          avgPos.divideScalar(neighbors.size);

          // Calculate new position as weighted average
          const newPos = new THREE.Vector3().lerpVectors(currentPos, avgPos, strength);

          // Set the new position
          // position.setXYZ(i, newPos.x, newPos.y, newPos.z);
          position[i*3] = newPos.x;
          position[i*3 + 1] = newPos.y;
          position[i*3 + 2] = newPos.z;
        });
        }


        // for (let iter = 0; iter < iterations; iter++) {
        //   connectedNeighbours.forEach((i) => {
        //     const currentPos = new THREE.Vector3(
        //       position.getX(i),
        //       position.getY(i),
        //       position.getZ(i)
        //     );
            
        //     // const neighbors = U.nRingNeighbors(adjacency, i, 1);
        //     const neighbors = adjacency[i];
        //     const avgPos = new THREE.Vector3();
        //     neighbors.forEach((neighborIndex) => {
        //       avgPos.add(new THREE.Vector3(
        //         position.getX(neighborIndex),
        //         position.getY(neighborIndex),
        //         position.getZ(neighborIndex)
        //       ));
        //     });
        //     avgPos.divideScalar(neighbors.size);

        //     // Calculate new position as weighted average
        //     const newPos = new THREE.Vector3().lerpVectors(currentPos, avgPos, strength);

        //     // Set the new position
        //     position.setXYZ(i, newPos.x, newPos.y, newPos.z);
        //   });
        // }

        // Mark the position attribute as needing an update
        geometry.attributes.position.needsUpdate = true;

        // Update geometry to recalculate normals if necessary
        geometry.computeVertexNormals();
        const startTime2 = performance.now();
        // toCreasedNormals(geometry, (90 / 180) * Math.PI);

        // Ensure object matrices are up to date
        // object.updateMatrix();
        // object.updateMatrixWorld();

        // console.log('Smoothing completed');
        // setSmoothpoints([]);
        console.log("Mesh Smootheining in:", startTime2-startTime1);
        // Force a re-render
        if (renderer && camera) {
          renderer.render(scene, camera);
        } else {
          console.warn('Renderer or camera not available');
        }
    } else {
      console.error('Scene not available');
    }
  }
  const undoTooling = () => {
    if (scene) {
        const object = scene.getObjectByName('crown_0');
        if (object && object.geometry && undoStack.length > 0) {
            // Retrieve the previous geometry from the undo stack
            const prevGeo = undoStack[undoStack.length - 1];
            object.geometry = prevGeo;

            // Update the undo stack
            setUndoStack(undoStack.slice(0, undoStack.length - 1));

            // Mark position attribute for update
            object.geometry.needsUpdate = true;

            // Recompute bounding sphere if necessary
            object.geometry.computeBoundingSphere();

            // Trigger scene re-render
            renderer.render(scene, camera); // Assuming `renderer` and `camera` are in scope
        }
    }
};


  function getDist(point) {
    if (inner_surface) {
      const dist = U.distance([point.x, point.y, point.z], inner_surface);
      setInner_distance(dist);
    }
  }

  const MarginfrontEnd = () => {
    if (scene) {
      const object = scene.getObjectByName('crown_0');
      if (object && object.geometry) {
        console.log('Object found:', object);
        const geometry = object.geometry;
        const position = geometry.attributes.position;
        const normal = geometry.attributes.normal;

        // Extract vertex positions
        const vertices = [];
        for (let i = 0; i < position.count; i++) {
          vertices.push([position.getX(i), position.getY(i), position.getZ(i)]);
        }

        // Create KD-tree
        const kdtree = new KdTree(vertices);

        // Define the start and end points
        const startPoint = new THREE.Vector3(marginstartpoint[0], marginstartpoint[1], marginstartpoint[2]);
        const endPoint = new THREE.Vector3(marginendpoint[0], marginendpoint[1], marginendpoint[2]);
        console.log('Start point:', startPoint);
        console.log('End point:', endPoint);

        // Function to get neighbors using KD-tree
        const getNeighbors = (vertex, k) => {
          const result = kdtree.knn(vertex, k);
          if (typeof result === 'number') {
            return [[result, 0]];
          }
          return result;
        };

        // Find the nearest vertex to the start point and get its normal
        const startNeighbors = getNeighbors([startPoint.x, startPoint.y, startPoint.z], 10);
        let startNormal = new THREE.Vector3(0, 0, 0);
        for (let i = 0; i < startNeighbors.length; i++) {
          const neighborIndex = startNeighbors[i][0];
          startNormal.add(new THREE.Vector3(
            normal.getX(neighborIndex),
            normal.getY(neighborIndex),
            normal.getZ(neighborIndex)
          ));
        }
        startNormal.normalize();

        if (startNormal.lengthSq() < 0.001) {
          // If we still don't have a valid normal, use the direction from start to end
          startNormal = endPoint.clone().sub(startPoint).normalize();
        }

        console.log('Start normal:', startNormal);

        // Calculate length of deformation
        const length = startPoint.distanceTo(endPoint);
        console.log('Deformation length:', length);

        // Deformation parameters
        const influenceRadius = Math.max(length / 2, 5.0); // Adjust based on model scale
        const strength = 1.0; // Adjust as needed
        console.log('Influence radius:', influenceRadius);
        console.log('Strength:', strength);

        // Function to compute smooth falloff
        const smoothFalloff = (distance, maxDistance) => {
          const x = distance / maxDistance;
          return 1 - (3 * x * x - 2 * x * x * x);
        };

        // Apply deformation
        let deformedVertexCount = 0;
        const newVertices = vertices.map((v, i) => {
          const vertex = new THREE.Vector3(...v);
          const distanceToStart = vertex.distanceTo(startPoint);

          if (distanceToStart < influenceRadius) {
            const deformationFactor = smoothFalloff(distanceToStart, influenceRadius);
            const moveVector = startNormal.clone().multiplyScalar(deformationFactor * strength * length);
            vertex.add(moveVector);
            deformedVertexCount++;

            // Apply simple smoothing
            const neighbors = getNeighbors(v, 6);
            let avgPos = new THREE.Vector3(0, 0, 0);
            let neighborCount = 0;
            neighbors.forEach(neighbor => {
              if (Array.isArray(neighbor)) {
                avgPos.add(new THREE.Vector3().fromArray(vertices[neighbor[0]]));
                neighborCount++;
              }
            });
            if (neighborCount > 0) {
              avgPos.divideScalar(neighborCount);
              vertex.lerp(avgPos, 0.3); // Blend with the average position
            }

            return [vertex.x, vertex.y, vertex.z];
          }

          return v;
        });

        console.log('Deformed vertex count:', deformedVertexCount);

        // Update vertex positions
        for (let i = 0; i < newVertices.length; i++) {
          position.setXYZ(i, newVertices[i][0], newVertices[i][1], newVertices[i][2]);
        }

        // Mark the position attribute as needing an update
        position.needsUpdate = true;

        // Recompute normals and bounding sphere for smooth rendering
        geometry.computeVertexNormals();
        geometry.computeBoundingSphere();

        // Ensure object matrices are up to date
        object.updateMatrix();
        object.updateMatrixWorld();

        console.log('Mesh modification completed');
        setMarginstartpoint([]);
        setMarginendpoint([]);

        // Force a re-render
        if (renderer && camera) {
          renderer.render(scene, camera);
        } else {
          console.warn('Renderer or camera not available');
        }

      } else {
        console.error('Object or geometry not found');
      }
    } else {
      console.error('Scene not available');
    }
  };

  const handleToggleVisibility = (index) => {
    if (threeJSManager) {
      const updatedSliderData = [...sliderData];
      updatedSliderData[index].visible = !updatedSliderData[index].visible;
      setSliderData(updatedSliderData);
      threeJSManager.handleToggleVisibilityClick(index);
    }
  };

  const updateCrownArrayBeforeExport = (callback) => {
    if (scene) {
      const exporter = new STLExporter();
      const newCrownArray = crownarray.map((crown, index) => {
        const object = scene.getObjectByName(`crown_${index}`);
        if (object) {
          const stlString = exporter.parse(object);
          const blob = new Blob([stlString], { type: 'text/plain' });
          return new File([blob], `crown_${index}.stl`);
        }
        return crown;
      });
      setCrownarray(newCrownArray);
      console.log('Crown array updated before export:', newCrownArray);
      if (callback) callback(newCrownArray);
    }
  };

  const handlesave = () => {
    updateCrownArrayBeforeExport((updatedCrownArray) => {
      navigate("/export", {
        state: {
          file1,
          file2,
          crown,
          crownFirst,
          marginCenter,
          thickness,
          position,
          axis,
          prepView,
          antaView,
          exportelements: updatedCrownArray
        }
      });
    });
  };

  const handleAlertClose = () => {
    setError(false);
  };
  const setdefaultview = () => {
    setInitialCameraPosition(position ? position : new THREE.Vector3(2.6, -17, 70));
  }
  const anatomyGen = async () => {
    if (center === null || distalcenter === null || mesialcenter === null) {
      alert('Please select all the points');
      return;
    }
    try {
      setLoading(true);
      const formData = new FormData();
      formData.append('prep', prep);
      formData.append('anta', anta);
      formData.append('file1', crownarray[indexforrequest]);
      formData.append('center', JSON.stringify(center));
      formData.append('distal', JSON.stringify(distalcenter));
      formData.append('mesial', JSON.stringify(mesialcenter));
      formData.append('category', JSON.stringify(category));

      const response = await fetch('https://api.dentalai.ai/anatomy/', {
        method: 'POST',
        body: formData,
      });

      if (response.ok) {
        const vectorOfAxis = response.headers.get('axis');
        const groovesheaders = response.headers.get('grooves');
        const groovesarr = JSON.parse(groovesheaders);
        console.log(groovesarr);
        setGrooves(groovesarr);
        const axis = JSON.parse(vectorOfAxis);
        const blob = await response.blob();
        const crownProcessed = new File([blob], 'processed_mesh.stl');
        const updatedCrown = [...crownarray];
        updatedCrown[indexforrequest] = crownProcessed;

        setCrownarray(updatedCrown);
        setanatomycenter(null);
        setdistal(null);
        setmesial(null);
        setVectorOfAxis(axis);
        // Handle the vectorOfAxis as needed, e.g., update state or log it
        setLoading(false);

      } else {
        setError(true)
        setLoading(false);

      }
    } catch (error) {
      setError(true)
      setLoading(false);

    }
  };

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };
  const showCrown = () => {
    setcrownView(!crownView);
    setTool(false);
  }
  const showTool = () => {
    setTool(!tool);
    setcrownView(false);
  }
  const brushingTool = () => {
    if (!brushing) {
      setIsRaycasterActive(true);
    }
    const object = scene.getObjectByName('crown_0');
    object.material.uniforms.uHoverPosition.value.set(-1, -1, -1);
    setBrushing(!brushing);
    setSmooth(false);
    setDeformation(false);
    setDistanceView(false);
    setMargin(false);
  }
  const anatomyTool = () => {
    if (!anatomy) {
      setIsRaycasterActive(true);
    }

    setAnatomy(!anatomy);
    setMargin(false);
  }
  const marginTool = () => {
    if (!margin) {
      setIsRaycasterActive(true);
    }

    setMargin(!margin);
    setDistanceView(false);
    setSmooth(false);
    setDeformation(false);
    setBrushing(false);
  }
  const setBrushingpoint = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setBrushingPoints([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }

  const marginEdit = () => {
    setMarginManipulationTool(!marginManipulationTool);
    setDistanceView(false);
    setSmooth(false);
    setDeformation(false);
    setBrushing(false);
  }
  const smoothTool = () => {
    if (!smooth) {
      setIsRaycasterActive(true);
    }
    const object = scene.getObjectByName('crown_0');
    object.material.uniforms.uHoverPosition.value.set(-1, -1, -1);
    setSmooth(!smooth);
    setDistanceView(false);
    setDeformation(false);
    setMargin(false);
    setBrushing(false);
  }
  const setanatomycenter = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setcenter([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const setdistal = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setdestialcenter([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const setmesial = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }

    setmesialcenter([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const handleCategorychange = (event) => {
    setCategory(event.target.value);
  }

  const setstart = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }
    setMarginIndex(indexforrequest);
    setMarginstartpoint([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const setend = () => {
    if (!selectedCoordinates) {
      alert('Please select a brushing point');
      return;
    }
    setMarginendpoint([selectedCoordinates.x, selectedCoordinates.y, selectedCoordinates.z]);
  }
  const getNewMargin = () => {
    const numPoints = 1500;
    const points = [];
    const curveLength = curveRef.current.getLength();
    const segmentLength = curveLength / numPoints; 
    let currentLength = 0;
  
    for (let i = 0; i < numPoints; i++) {
      // Find the point at the desired arc length
      const t = curveRef.current.getUtoTmapping(currentLength / curveLength);
      const point = curveRef.current.getPoint(t);
      points.push(point);
      currentLength += segmentLength;
    }
  
    // Add the final point explicitly to close the curve
    points.push(curveRef.current.getPoint(1));
  
    // Create PCD header
    let header = `# .PCD v0.7 - Point Cloud Data\n`;
    header += `VERSION 0.7\n`;
    header += `FIELDS x y z\n`;
    header += `SIZE 4 4 4\n`;
    header += `TYPE F F F\n`;
    header += `COUNT 1 1 1\n`;
    header += `WIDTH ${points.length}\n`;
    header += `HEIGHT 1\n`;
    header += `VIEWPOINT 0 0 0 1 0 0 0\n`;
    header += `POINTS ${points.length}\n`;
    header += `DATA ascii\n`;
  
    // Create points data
    let pointsData = '';
    points.forEach((point) => {
      const x = point.x.toFixed(6);
      const y = point.y.toFixed(6);
      const z = point.z.toFixed(6);
      pointsData += `${x} ${y} ${z}\n`;
    });
  
    // Combine header and points data
    const pcdData = header + pointsData;
    return pcdData;
  };
  const handleRegenerate = async () => {
      const pcdData = getNewMargin();
      const MarginPCDfile = new Blob([pcdData], { type: 'text/plain' });
      try {
        setLoading(true);
        const formData = new FormData();
        formData.append('file1', file1);
        formData.append('file2', file2);
        formData.append('margin', MarginPCDfile);
        formData.append("category", JSON.stringify(selectedOption))
        formData.append('folder_id', JSON.stringify(uuid_crown));
    
        const response = await fetch('https://api.dentalai.ai/gen_crown_anatomical/', {
          method: 'POST',
          body: formData,
        });
    
        if (response.ok) {
          const contentType = response.headers.get('content-type');
    
          if (contentType === 'application/json') {
            // Parse the JSON response
            const responseData = await response.json();
    
            // Extract data from the JSON response
            const {
              zip_file,
              margin_center: marginCenter,
              axis,
              thickness: thicknessData,
              inner_surface
            } = responseData;
    
            const thickness = thicknessData === true;
            const crownFirst = true;
    
            // Convert base64 zip data to a Blob
            const zipBlob = new Blob([Uint8Array.from(atob(zip_file), c => c.charCodeAt(0))], { type: 'application/zip' });
    
            // Process the ZIP blob to create separate File objects for each mesh
            const zip = await JSZip.loadAsync(zipBlob);
    
            const meshFiles = [];
            const promises = [];
    
            zip.forEach((relativePath, file) => {
              if (file.dir) return; // Ignore directories
              const promise = file.async('uint8array').then((data) => {
                const meshBlob = new Blob([data], { type: 'application/octet-stream' });
                const mesh = new File([meshBlob], file.name, { type: 'application/octet-stream' });
                meshFiles.push(mesh);
              });
              promises.push(promise);
            });
    
            // Wait for all promises to resolve
            await Promise.all(promises);

            setCrownarray(meshFiles);
            
            setError(false);
            setLoading(false);
            
    
          
          } else {
            setError(true);
            setLoading(false);
          }
        }
        else {
          setError(true);
          setLoading(false);
        }
      } catch (error) {
        setError(true);
        setLoading(false);
        console.error('Error generating crown:', error);
      }
      
    }

    const defomationTooling = () =>{
      setDeformation(!deformation);
      setMargin(false);
      setSmooth(false);
      setAnatomy(false);
      setBrushing(false);
      setDistanceView(false);
  
    }

  const brushingView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: brushing ? 'block' : 'none',
         zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center' style={{ color: 'black' }}>
          <div>Brush</div>
          <div onClick={brushingTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column' style={{ color: 'black' }}>
            <div>
              Radius
            </div>
            <input
              style={{ display: 'flex' }}
              type="range"
              id={'brushingradius'}
              min="0.1"
              max="4"
              step="0.1"
              defaultValue="2"
            />
            <div>
              Strength
            </div>

            <input
              style={{ display: 'flex' }}
              type="range"
              id={`brushingstrength`}
              min="0.001"
              max="0.3"
              step="0.001"
              defaultValue="0.05"
            />
          </div>

        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />
        <div className='mt-3 rounded' style={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: addsub === 1 ? primaryColor : "#ffffff",
          color: addsub === 1 ? "#ffffff" : "black",
          border: "1px solid #1F555A",
          transition: 'background-color 0.3s ease',
        }} onClick={() => { setaddsub(1) }}>Add</div>

        <div className='mt-3 rounded' style={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: addsub === -1 ? primaryColor : "#ffffff",
          color: addsub === -1 ? "#ffffff" : "black",
          border: "1px solid #1F555A",
          transition: 'background-color 0.3s ease',
        }} onClick={() => { setaddsub(-1) }}>Sub</div>
      </div>
    </>
  );
  const marginView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: margin ? 'block' : 'none', zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Margin</div>
          <div onClick={marginTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column'>
            <select
              className="form-select text-light my-2"
              style={{
                backgroundColor: primaryColor,
                width: "auto",

              }}
              value={selectedOption}
              onChange={handleOptionChange}
            >
              <option value="low">Low</option>
              <option value="medium">Medium</option>
              <option value="high">High</option>
            </select>
            <div className="d-flex flex-row " style={{ textAlign: "center", justifyContent: "space-between" }}>
              <div className='me-3'>Start Point</div>
              <div className='ms-3'
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
              >{!marginstartpoint ? <MySVG10 onClick={setstart} /> : <MySVG5 onClick={() => { setMarginstartpoint(null) }} />}</div>


            </div>
            <div className="d-flex flex-row " style={{ textAlign: "centesmmr", justifyContent: "space-between" }}>
              <div className='me-3'>End Point</div>
              <div className='ms-3'
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
              >{!marginendpoint ? <MySVG10 onClick={setend} /> : <MySVG5 onClick={() => { setMarginendpoint(null) }} />}</div>


            </div>

          </div>
        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />
        <div className='mt-3 rounded-3' style={{
          cursor: "pointer",
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          backgroundColor: primaryColor,
          color: '#fff',
          border: "1px solid #1F555A",
          transition: 'background-color 0.3s ease',
        }} onClick={MarginfrontEnd} >Process</div>

      </div>
    </>
  )


  const marginmanipultaionView = (
        <>
          <div className="p-3 text-center position-absolute rounded-4" style={{
            display: marginManipulationTool ? 'block' : 'none',
           zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
          }}>
            <div className='mb-3 d-flex justify-content-between align-items-center'>
              <div>Margin</div>
              <div onClick={()=>{
                setMarginManipulationTool(false)
              }}><Delete /></div>
            </div>
            <button
          className='btn btn-primary'
          style={{
            backgroundColor:primarycolor,
            textDecorationColor:"#ffffff",
            margin: '5px',
            
            fontWeight:"bolder",
            fontFamily: 'Manrope, sans-serif'
          }}
          onClick={handleRegenerate}
        >
          Regenerate
        </button>
          </div>
        </>
      )
  const distTool = () => {
    if (!isRaycasterActive) {
      setIsRaycasterActive(true);
    }
    setDistanceView(!distanceView);
    setSmooth(false);
    setDeformation(false);
    setMargin(false);
    setMargin(false);
    setBrushing(false);

  }
  const calculatDistanceView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: distanceView ? 'block' : 'none',
         zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Thickness Tool</div>
          <div onClick={distTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column'>
            <div className="d-flex flex-row justify-content-center mt-2">
              Thickness: {inner_distance ? <div > {parseFloat(inner_distance).toFixed(4)} </div> : 0} mm
            </div>
          </div>
        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />




      </div>
    </>


  )

  // const DeformationView = (
  //   <>
  //     <div className="p-3 text-center position-absolute rounded-4" style={{
  //       display: deformation ? 'block' : 'none',
  //      zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
  //     }}>
  //       <div className='mb-3 d-flex justify-content-between align-items-center'>
  //         <div>Deformation</div>
  //         <div onClick={()=>{
  //           setDeformation(false)
  //           setRotatetool(false)
  //           setScaletool(false)
  //           setTranslatetool(false)
            
  //           }}><Delete /></div>
  //       </div>
  //       <div className='mt-3 rounded' style={{
  //         cursor: "pointer",
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         backgroundColor: primaryColor,
  //         color: '#fff',
  //         border: "1px solid #1F555A",
  //         transition: 'background-color 0.3s ease',
  //       }} onClick={()=>{
  //         setRotatetool(true)
  //         setScaletool(false)
  //         setTranslatetool(false)}
          
  //         } >Rotate</div>

  //       <div className='mt-3 rounded' style={{
  //         cursor: "pointer",
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         backgroundColor: primaryColor,
  //         color: '#fff',
  //         border: "1px solid #1F555A",
  //         transition: 'background-color 0.3s ease',
  //       }} onClick={()=>{
  //         setRotatetool(false)
  //         setScaletool(true)
  //         setTranslatetool(false)}
          
  //         }  >Scale</div>

  //       <div className='mt-3 rounded' style={{
  //         cursor: "pointer",
  //         display: 'flex',
  //         alignItems: 'center',
  //         justifyContent: 'center',
  //         backgroundColor: primaryColor,
  //         color: '#fff',
  //         border: "1px solid #1F555A",
  //         transition: 'background-color 0.3s ease',
  //       }} onClick={()=>{
  //         setRotatetool(false)
  //         setScaletool(false)
  //         setTranslatetool(true)}
          
  //         }  >Translate</div>

        

  //     </div>
  //   </>
  // )

  const DeformationView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: deformation ? 'block' : 'none',
zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Deformation</div>
          <div onClick={() =>{setDeformation(false)}}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column' style={{ color: 'black' }}>
            <div>
              Radius
            </div>
            <input
              style={{ display: 'flex' }}
              type="range"
              id={'deformationradius'}
              min="0.7"
              max="5.5"
              step="0.1"
              defaultValue="3"
            />
          </div>

        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />


      </div>
    </>
  )

  const prepselection = (
    <div className="p-3 text-center position-absolute rounded-4" style={{
      display: crownView ? 'block' : 'none',
      minWidth: "15vw", zIndex: "4", background: "rgba(255, 255, 255, 1)", top: "15%", left: "-160%", transform: "translate(-50%, -50%)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
    }}>

      <div className='mb-3 d-flex justify-content-between align-items-center'>
        <div>Crown Properties</div>
        <div onClick={showCrown}><Delete /></div>
      </div>

      <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
        {crown.map((item, index) => (
          <div key={index} className='d-flex flex-column'>
            <div className="d-flex flex-row " style={{ justifyContent: "space-between" }}>
              <div className='mx-3'>Crown {index}</div>
              <div className='mx-3' id={`toggleVisibilityButton-${index + 2}`}
                style={{
                  cursor: 'pointer',
                  transition: 'background-color 0.3s ease',
                }}
                onClick={() => handleToggleVisibility(index + 2)}><Visiblity /></div>
            </div>
            <div className='d-flex flex-row'>
              <input
                style={{ display: 'flex' }}
                type="range"
                id={`opacitySlider-${index}`}
                min="0.1"
                max="1"
                step="0.01"
                defaultValue="1"
              />


            </div>
            <div className='d-flex flex-row'>
              <input
                style={{ display: 'none' }}
                type="range"
                id={`shine`}
                min="0.1"
                max="1"
                step="0.01"
                defaultValue="1"
              />


            </div>
            {/* <ChromePicker color={crownColor} ref={color} onChangeComplete={handleColorChange} /> */}
          </div>
        ))}
      </ul>

    </div>
  );
  const smoothView = (
    <>
      <div className="p-3 text-center position-absolute rounded-4" style={{
        display: smooth ? 'block' : 'none',
zIndex: "4", background: "rgba(255, 255, 255, 1)", boxShadow: "0 0 2px 2px rgba(144, 200, 224, 0.4)"
      }}>
        <div className='mb-3 d-flex justify-content-between align-items-center'>
          <div>Smooth</div>
          <div onClick={smoothTool}><Delete /></div>
        </div>

        <ul className='list-group d-flex flex-column align-items-center justify-content-center p-2 rounded-3' style={{ background: "rgba(144, 200, 224, 0.3)" }}>
          <div className='d-flex justify-content-between flex-column'>
            <div className="d-flex flex-row " style={{ textAlign: "center", justifyContent: "space-between" }}>
              <div className='d-flex justify-content-between flex-column' style={{ color: 'black' }}>
                <div>
                  Radius
                </div>
                <input
                  style={{ display: 'flex' }}
                  type="range"
                  id={'smootheningradius'}
                  min="0.1"
                  max="4"
                  step="0.1"
                  defaultValue="2"
                />

<div>
              Strength
            </div>

            <input
              style={{ display: 'flex' }}
              type="range"
              id={`smootheningstrength`}
              min="0.1"
              max="1"
              step="0.1"
              defaultValue="0.3"
            />
              </div>



            </div>

          </div>
        </ul>
        <hr style={{ width: "100%", border: "1px solid #000" }} />


      </div>
    </>
  )
  const tooling = (
    <div
    className="p-2 text-center position-absolute rounded-4"
    style={{
      display: tool ? 'block' : 'none',
      zIndex: '4',
      background: 'rgba(255, 255, 255, 1)',
      bottom: '2%',  // Changed from '5px' to '20px' for better spacing
      left: '50%',     // Added to help with centering
      transform: 'translateX(-50%)', // Added to center horizontally
      boxShadow: '0 0 2px 2px rgba(144, 200, 224, 0.4)',
    }}
  >
      <div className=" d-flex justify-content-between align-items-center">
        <div>Tooling</div>
        <div onClick={showTool}>
          <Delete />
        </div>
      </div>

      <ul
        className="list-group d-flex flex-wrap align-items-center justify-content-center p-2 rounded-3"
        style={{ background: 'rgba(144, 200, 224, 0.3)' }}
      >
        <div className="d-flex flex-wrap justify-content-between">
          <div className="d-flex flex-column align-items-center mx-4" style={{ width: '80px' }}>
            <button
              className="rounded"
              onClick={brushingTool}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Brush />
            </button>
            <div className='fw-light'>Brush</div>
          </div>
          {/* <div className="d-flex flex-column align-items-center mx-4 mb-3" style={{ width: '80px' }}>
            <button
              id="prep"
              className="rounded"
              onClick={marginTool}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Margin style={{ fill: '#ffffff' }} />
            </button>
            <div>Margin</div>
          </div> */}
          <div className="d-flex flex-column align-items-center mx-4 " style={{ width: '80px' }}>
            <button
              id="prep"
              className="rounded"
              onClick={marginEdit}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Margin style={{ fill: '#ffffff' }} />
            </button>
            <div className='fw-light'> Margin </div>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 " style={{ width: '80px' }}>
            <button
              id="prep"
              className="rounded"
              onClick={smoothTool}
              style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Smooth style={{ fill: '#ffffff' }} />
            </button>
            <div className='fw-light'>Smooth</div>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 " style={{ width: '80px' }}>
            <button
              className="rounded"
              onClick={distTool}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Brush />
            </button>
            <div className='fw-light'>Thickness</div>
          </div>
          <div className="d-flex flex-column align-items-center mx-4 " style={{ width: '80px' }}>
            <button
              className="rounded"
              onClick={defomationTooling}
              style={{
                textAlign: 'center',
                justifyContent: 'center',
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '30px',
                height: '30px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #1F555A',
                transition: 'background-color 0.3s ease',
              }}
            >
              <Brush />
            </button>
            <div className='fw-light'>Deformation</div>
          </div>
        </div>
      </ul>
    </div>
  );
  const adjustment = (
    <div>
      <div className="p-3 text-center position-absolute rounded-4" style={{ zIndex: "2", background: "rgba(144, 200, 224, 0.3)", top: "45%", right: "10%", transform: "translate(50%, -50%)", boxShadow: "0 2px 4px rgba(144, 200, 224, 0.4)", }}>
        <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={
          {
            cursor: 'pointer',
            transition: "transform 1s ease"

          }
        }
        // onClick={showPanel}
        >
          <MySVG className='mb-1' />
          <MySVG className='mb-1' />
        </div>
        {/* Move prepview && prepselection inside the adjustment panel */}
        {prepselection}
        {Adjustmentpanel && (<>
          <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
            {/* Render prepview */}


            <div>Crown</div>
            <OverlayTrigger
              placement="left"
              overlay={<Tooltip >Adjust Crowns Visiblity</Tooltip>}
            >
              <button id="prep" className='rounded' onClick={showCrown} title='Use this button to Change prep selection' style={{
                cursor: 'pointer',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                width: '40px',
                height: '40px',
                backgroundColor: primaryColor,
                color: '#fff',
                border: "1px solid #1F555A",
                transition: 'background-color 0.3s ease',
              }}
              >
                <MySVG4 />
              </button>
            </OverlayTrigger>

          </div>

          <hr style={{ width: "100%", border: "1px solid #000" }} />

          <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3 ' style={{ background: "rgba(255,255,255,1" }}>
            {/* Model 0 */}
            <div className=" container ">
              <label className="container" style={{ fontFamily: 'Manrope, sans-serif' }}>{sliderData[0].name}</label>
              <OverlayTrigger

                placement="left"
                overlay={<Tooltip >Toggle Prep/Anta visibility</Tooltip>}
              >
                <div
                  className="container rounded-3"
                  id={'toggleVisibilityButton-0'}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    backgroundColor: sliderData[0].visible ? primaryColor : '#ffffff',
                    color: '#fff',
                    border: "1px solid #1F555A",

                    transition: 'background-color 0.3s ease',
                  }}
                  onClick={() => handleToggleVisibility(0)}
                >
                  {sliderData[0].visible ? <MySVG1 /> : <MySVG8 />}
                </div>
              </OverlayTrigger>
            </div>

            {/* Model 1 */}
            <div className=" container">
              <label className="container">{sliderData[1].name}</label>
              <OverlayTrigger

                placement="left"
                overlay={<Tooltip >Toggle Prep/Anta visibility</Tooltip>}
              >
                <div
                  className="container rounded-3"
                  id={'toggleVisibilityButton-1'}
                  style={{
                    cursor: 'pointer',
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: '40px',
                    height: '40px',
                    backgroundColor: sliderData[1].visible ? primaryColor : '#ffffff',
                    color: '#fff',
                    border: "1px solid #1F555A",
                    transition: 'background-color 0.3s ease',
                  }}
                  onClick={() => handleToggleVisibility(1)}
                >
                  {sliderData[1].visible ? <MySVG9 /> : <MySVG7 />}
                </div>
              </OverlayTrigger>

            </div>

          </div>

          <hr style={{ width: "100%", border: "1px solid #000" }} />

          <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
            <div>Grid</div>
            <OverlayTrigger

              placement="left"
              overlay={<Tooltip >Show/Hide grid View
              </Tooltip>}
            >
              <button id='grid' className='rounded'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  backgroundColor: primaryColor,
                  color: '#fff',
                  border: "1px solid #1F555A",
                  transition: 'background-color 0.3s ease',
                }} ><MySVG3 color='#808080' /></button></OverlayTrigger>
            <div>Wireframe</div>
            <OverlayTrigger

              placement="left"
              overlay={<Tooltip >Show/Hide wireframe view
              </Tooltip>}
            >
              <button id='wireframe' className='rounded'
                style={{
                  cursor: 'pointer',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: '40px',
                  height: '40px',
                  backgroundColor: primaryColor,
                  color: '#fff',
                  border: "1px solid #1F555A",
                  transition: 'background-color 0.3s ease',
                }} >
                <MySVG2 />
              </button></OverlayTrigger>
          </div>


        </>
        )}




        <hr style={{ width: "100%", border: "1px solid #000" }} />
        <div className='d-flex flex-column align-items-center justify-content-center p-1 rounded-3' style={{ background: "rgba(255,255,255,1" }}>
          <div>Toolbox</div>
          <button
            className='btn '
            style={{
              backgroundColor: primaryColor,
              textDecorationColor: "#ffffff",

              fontFamily: 'Manrope, sans-serif'
            }}
            onClick={() => { setTool(!tool) }}
          >
            <Toolbox />
          </button>
        </div>

      </div>

    </div>
  )


  return (
    <>
      <div className={` d-flex `} style={{ overflow: 'hidden', backgroundColor: "#ffffff", fontFamily: 'Manrope, sans-serif', fontWeight: "bold" }}>

        {loading && (
          <div style={{
            position: 'fixed',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            zIndex: '9999',
            backgroundColor: 'rgba(255, 255, 255, 0.8)',
            padding: '20px',
            borderRadius: '5px',
            textAlign: 'center',
            backdropFilter: 'blur(10px)',
            width: "50vw"
          }}>
            {/* <div className="mb-3">
            <h2 className="card-title mb-4 text-center fw-semibold">Do you know?</h2>
            <p>
              <strong> {randomFact.fact}</strong>
            </p>
          </div> */}
            <ClipLoader size={50} color="#007bff" loading={loading} />
          </div>
        )}

        <div className="flex-1" style={{ position: 'relative' }}>
          <div id="canvas-container" ref={containerRef}>
            {error && (
              <div className="alert alert-danger alert-dismissible fade show" role="alert" style={{ position: 'absolute', top: '10px', left: '10px', zIndex: '1000', width: "72vw" }}>
                Some Error Occured!
                <button type="button" className="btn-close" data-bs-dismiss="alert" aria-label="Close" onClick={handleAlertClose}></button>
              </div>
            )}
            <div id='defaultview' style={{ zIndex: 2000, position: "absolute", left: "2%", top: "2%", cursor: "pointer" }} onClick={setdefaultview}>
              <OverlayTrigger

                placement="bottom"
                overlay={<Tooltip >Reset to default view
                </Tooltip>}
              >
                <View /></OverlayTrigger>
              <div>Default View</div>

            </div>
            {/* <div style={{ zIndex: 2000, position: "absolute", left: "30%", top: "10%" }}
            >
              {statusbar}
            </div> */}


            <div style={{ zIndex: 2000, width:"10vw" , position: "absolute", left: "2%", top: "50%", cursor: "pointer" }} >
              {brushingView}
              {marginView}
              {smoothView}
              {calculatDistanceView}
              {marginmanipultaionView}
              {DeformationView}
            </div>


          </div>
        </div>
        {
          adjustment
        }

<div className='position-absolute ' style={{
          left: '5%',
          bottom: '3%'
        }}>


          {/* <button
            className='btn btn-primary fs-5'
            style={{
              backgroundColor: primaryColor,
              textDecorationColor: "#ffffff",
              margin: '10px',
              fontWeight: 'bolder',
              fontFamily: 'Manrope, sans-serif'
            }}
            onClick={()=>{updateCrownArrayBeforeExport()}}
          >
            Save Progress
          </button> */}


        </div>
            
        {tooling}


        <div className='position-absolute ' style={{
          right: '10%',
          bottom: '3%'
        }}>


          <button
            className='btn btn-primary fs-5'
            style={{
              backgroundColor: primaryColor,
              textDecorationColor: "#ffffff",
              margin: '10px',
              fontWeight: 'bolder',
              fontFamily: 'Manrope, sans-serif'
            }}
            onClick={handlesave}
          >
            Export Crown
          </button>


        </div>


        <div className='position-absolute ' style={{
          left: '2%',
          bottom: '3%'
        }}>


          <button
            className='btn btn-primary fs-5'
            style={{
              backgroundColor: undoStack.length > 0 ? primaryColor : "#808080",
              textDecorationColor: "#ffffff",
              margin: '10px',
              fontWeight: 'bolder',
              fontFamily: 'Manrope, sans-serif',
              cursor: undoStack.length > 0 ? "pointer" : "not-allowed",
            }}
            onClick={undoTooling}
          >
            Undo
          </button>


        </div>

      </div>
    </>
  );
};

export default Crown;