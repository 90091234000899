import React, { useState, useRef, useEffect } from 'react';
import { Box, Button, Card, CircularProgress, FormControl, Grid, InputLabel, MenuItem, Select, Typography } from '@mui/material';
import { IoAddCircleSharp } from 'react-icons/io5';
import { ClipLoader } from 'react-spinners';
import dentalFacts from './Json/dentalFacts.json';
import { useNavigate } from 'react-router-dom';
import * as THREE from 'three';
import { STLLoader } from 'three/examples/jsm/loaders/STLLoader';
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js';
import { TrackballControls } from 'three/examples/jsm/controls/TrackballControls.js';
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import {ReactComponent as Info} from '../assets/info.svg';



const Fileselect = () => {
  const [file1, setFile1] = useState(null);
  const [file2, setFile2] = useState(null);
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [error, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState('molar');
  const randomFact = dentalFacts[Math.floor(Math.random() * dentalFacts.length)];
  const canvasRef = useRef(null);
  const scene = useRef(null);
  const [crownfile, setCrownfile] = useState([]);
  const [fileValid, setFileValid] = useState(true);
  const primaryColor = '#213F99';
  const debugToggle = false;
  const margintest = true;

  const handleFileChange = (e, fileType) => {
    e.preventDefault();
    let file;
  
    if (e.dataTransfer && e.dataTransfer.files.length > 0) {
      // If files are dropped (drag and drop)
      file = e.dataTransfer.files[0];
    } else if (e.target && e.target.files.length > 0) {
      // If files are selected (file input)
      file = e.target.files[0];
    }
  
    if (!file) {
      // Handle case where no file is selected or dropped
    
      return;
    }
  
    // Handle the file based on the fileType (e.g., 'file1' or 'file2')
    switch (fileType) {
      case 'file1':
        setFile1(file);
        break;
      case 'file2':
        setFile2(file);
        break;
      case 'crown':
        const crownarr = [...crownfile, file]
        setCrownfile(crownarr)
      default:
       
    }
  };
  
  

  const handleOptionChange = (event) => {
    setSelectedOption(event.target.value);
  };

  const prep = async () => {
    setLoading(true);
    if (!file1 || !file2) {
      setError(true);
      setLoading(false);
      return;
    }
  
    try {
      if (!file1 && !file2) {
        setError(true);
        setLoading(false);
        return;
      }
      
      if (
        file1.name.split('.').pop() !== 'stl' &&
        file1.name.split('.').pop() !== 'pcd' &&
        file2.name.split('.').pop() !== 'stl' &&
        file2.name.split('.').pop() !== 'pcd'
      ) {
        setFileValid(false);
        setLoading(false);
        return;
      }
  
      const formData = new FormData();
      formData.append('file1', file1);
      formData.append('category', JSON.stringify(selectedOption));
  
      const response = await fetch('https://api.dentalai.ai/prep/', {
        method: 'POST',
        body: formData,
      });
  
      if (response.status === 200) {
        // Get the response as a blob
        const responseBlob = await response.blob();
        const folder_id = response.headers.get('folder-id');

        console.log(folder_id);
  
        // Now you can use `blobURL` as the `margin` or any other place where needed
        setLoading(false);
        
        // Pass `blobURL` as `points` to the next view
        navigate('/prep', {
          state: {
            file1,
            file2,
            margin_backend: responseBlob,  // Passing the blob URL
            folder_id: folder_id,
            selectedOption,
            prepView: true,
            antaView: false
          }
        });
      } else {
        setError(true);
        setLoading(false);
      }
    } catch (error) {
      setError(true);
      setLoading(false);
    }
  };
  

  const debug = () => {
    navigate('/crown', {
      state: {
        file1,
        file2,
        crown: crownfile,
        crownFirst:null,
        marginCenter:null,
        axis:null,
        thickness:null,
        position: new THREE.Vector3(-42.57305524045056, -53.444090713186604, 9.906063101665028),
        selectedOption:null,
        prepView: true,
        antaView: false
      }
    });}


    const marginGo = () => {
      navigate('/example', {
        state: {
          prep : file1,
          crown : file2,
        }
      });

  }
  

  useEffect(() => {
    if (file1 || file2) {
      const renderer = new THREE.WebGLRenderer({ canvas: canvasRef.current, antialias: true,alpha: true});
      renderer.shadowMap.enabled = true;
      const sceneInstance = new THREE.Scene();
      scene.current = sceneInstance; // Update scene reference
      const camera = new THREE.PerspectiveCamera(75, canvasRef.current.clientWidth / canvasRef.current.clientHeight, 0.1, 1000);
      camera.position.set(2.6, -17,70);

      const loader = new STLLoader();
      scene.current.add(new THREE.AmbientLight(0xffffff, 0.4)); // Adjust intensity if needed
    const intensity = 0.9;
    const color = 0xffffff;
    const directionalLight = new THREE.DirectionalLight(color, intensity);
    directionalLight.position.set(0, 0, 1);
    scene.current.add(directionalLight);
      const controls = new OrbitControls(camera, renderer.domElement);
      controls.enableKeys = false;
      controls.enableRotate = true;
    controls.enablePan = true;
    controls.enableZoom = true;
    controls.enableDamping = true;
    controls.dampingFactor = 0.8;
    controls.rotateSpeed = 0.5;
    controls.zoomSpeed = 1.2;
    controls.panSpeed = 1;
    controls.minPolarAngle = 0;
    controls.maxPolarAngle = Math.PI;
    controls.target.set(0, 0, 0);

    // Enable full rotation around the target point (center of the scene)
    controls.minAzimuthAngle = -Infinity;
    controls.maxAzimuthAngle = Infinity;

    // Adjust these settings based on your preferences
    controls.maxDistance = 500;
    controls.minDistance = 5;
    controls.screenSpacePanning = true;
    controls.mouseButtons = { RIGHT: THREE.MOUSE.ROTATE, MIDDLE: THREE.MOUSE.DOLLY, LEFT: THREE.MOUSE.PAN };

      // Load and render file1
      if (file1) {
        loader.load(URL.createObjectURL(file1), (geometry) => {
          const material = new THREE.MeshPhysicalMaterial({ color: 0xffffff,              // Set color to white
          // metalness: 0.8,               // Metalness
          roughness: 0.5,               // Roughness
          clearcoat: 1.0,               // Clearcoat intensity
          clearcoatRoughness: 0.1,      // Clearcoat roughness
          reflectivity: 1.0,            // Reflectivity
          transmission: 0.0,            // Transmission (opacity)
          side: THREE.DoubleSide,       // Render both sides of the geometry
          // transparent: true,
          wireframeLinewidth: 0.1,
          wireframeLinecap: 'round',
          wireframeLinejoin: 'round', });
          const mesh = new THREE.Mesh(geometry, material);
          scene.current.add(mesh);
        });
      }
      if (file2) {
        loader.load(URL.createObjectURL(file2), (geometry) => {
          const material = new THREE.MeshPhysicalMaterial({color: 0xffffff,              // Set color to white
          // metalness: 0.8,               // Metalness
          roughness: 0.5,               // Roughness
          clearcoat: 1.0,               // Clearcoat intensity
          clearcoatRoughness: 0.1,      // Clearcoat roughness
          reflectivity: 1.0,            // Reflectivity
          transmission: 0.0,            // Transmission (opacity)
          side: THREE.DoubleSide,       // Render both sides of the geometry
          // transparent: true,
          wireframeLinewidth: 0.1,
          wireframeLinecap: 'round',
          wireframeLinejoin: 'round' });
          const mesh = new THREE.Mesh(geometry, material);
          scene.current.add(mesh);
        });
      }
      function light_update() {
        const distanceBack = 50; // Adjust this value based on how far back you want the light
      
        const cameraDirection = new THREE.Vector3();
        camera.getWorldDirection(cameraDirection);
      
        // Calculate the position by subtracting the direction multiplied by the distance
        const newPosition = new THREE.Vector3().copy(camera.position).sub(cameraDirection.multiplyScalar(distanceBack));
      
        directionalLight.position.copy(newPosition);
      }

      
      

      controls.addEventListener('change', light_update);

      const animate = () => {
        requestAnimationFrame(animate);
        if (scene.current && camera) { // Check if scene and camera are defined
          renderer.render(scene.current, camera);
          controls.update();

        }
      };

      animate();

      // Cleanup function to dispose the renderer on component unmount
      return () => {
        renderer.dispose();
        controls.dispose();
      };
    }
  }, [file1, file2,canvasRef.current]);
  return (
    <div style={{ fontFamily: 'Manrope, sans-serif' }}>
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', minHeight: '100vh', background: "rgba(255, 255, 255, 0.3)" }}>
        <Card sx={{ width: '90%', maxWidth: '60vw', p: 4, borderRadius: '8px', boxShadow: "0 0 4px 4px rgba(144, 200, 224, 0.4)", display: 'flex', flexDirection: "column" }}>
          {/* Error Message */}
          
  
          {/* Select Category */}
          <div style={{ background: "#ffffff" }}>
            <Box sx={{ flex: 1, padding: '20px' }}>
              <FormControl fullWidth>
                <InputLabel sx={{ }} id="selectLabel">Select category</InputLabel>
                <Select
                  labelId="selectLabel"
                  sx={{ backgroundColor: '#ffffff', }}
                  id="select"
                  value={selectedOption}
                  onChange={handleOptionChange}
                  label="Select category"
                >
                  <MenuItem value="molar">Molar</MenuItem>
                  <MenuItem value="premolar">Pre Molar</MenuItem>
                  <MenuItem value="anterior">Anterior</MenuItem>
                </Select>
              </FormControl>
            </Box>
          </div>
  
          {/* Left Half - Upload Cards */}
          <Box sx={{ display: "flex", flexDirection: "row" }}>
            <Box sx={{ flex: 1, display: 'flex', flexDirection: 'column', justifyContent: 'space-between', padding: '20px' }}>
              <Card sx={{ marginBottom: '20px' }}>
                <div style={{ background: "rgba(144, 200, 224, 0.1)" }}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px dashed #cccccc',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFileChange(e, 'file1');
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput1"
                      accept=".stl, .pcd"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileChange(e, 'file1')
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="fileInput1">
                      <Button sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: primaryColor,
                        color: '#fff',
                        border: `1px solid ${primaryColor}`,
                        transition: 'background-color 0.3s ease',
                      }} component="span" startIcon={<CloudUploadIcon />}>
                        Upload Prep
                      </Button>
                    </label>
                    {file1 && (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {file1.name} {/* Display the uploaded file name */}
                        </Typography>
                        <Button variant="outlined" style={{ marginTop: '10px', color: primaryColor }} onClick={() => setFile1(null)}>
                          Delete
                        </Button>
                      </>
                    )}
                    {!file1 && (
                      <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px' }}>
                        Drag and drop files here or click to upload<br />
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px', justifyContent: "center", textAlign: "center" }}>
                          .STL, .PLY, .OBJ, .DCM supported
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </div>
              </Card>
  
              <Card>
                <div style={{ background: "rgba(144, 200, 224, 0.1)" }}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px dashed #cccccc',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFileChange(e, 'file2');
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput2"
                      accept=".stl, .pcd"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileChange(e, 'file2')
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="fileInput2">
                      <Button sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: primaryColor,
                        color: '#fff',
                        border: "1px solid #213F99",
                        transition: 'background-color 0.3s ease',
                      }} component="span" startIcon={<CloudUploadIcon />}>
                        Upload Anta
                      </Button>
                    </label>
                    {file2 && (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {file2.name} {/* Display the uploaded file name */}
                        </Typography>
                        <Button variant="outlined" style={{ marginTop: '10px', color: primaryColor }} onClick={() => setFile2(null)}>
                          Delete
                        </Button>
                      </>
                    )}
                    {!file2 && (
                      <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px' }}>
                        Drag and drop files here or click to upload<br />
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px', justifyContent: "center", textAlign: "center" }}>
                          .STL, .PLY, .OBJ, .DCM supported
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </div>
              </Card>

              <Card>
                <div style={{ background: "rgba(144, 200, 224, 0.1)" ,display: debugToggle ? 'block' : 'none', }}>
                  <Box
                    sx={{
                      p: 2,
                      display: 'flex',
                      alignItems: 'center',
                      flexDirection: 'column',
                      border: '2px dashed #cccccc',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    onDragOver={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                    }}
                    onDrop={(e) => {
                      e.preventDefault();
                      e.stopPropagation();
                      handleFileChange(e, 'crown');
                    }}
                  >
                    <input
                      type="file"
                      id="fileInput3"
                      accept=".stl, .pcd"
                      onChange={(e) => {
                        e.preventDefault();
                        e.stopPropagation();
                        handleFileChange(e, 'crown')
                      }}
                      style={{ display: 'none' }}
                    />
                    <label htmlFor="fileInput3">
                      <Button sx={{
                        cursor: 'pointer',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        backgroundColor: primaryColor,
                        color: '#fff',
                        border: "1px solid #213F99",
                        transition: 'background-color 0.3s ease',
                      }} component="span" startIcon={<CloudUploadIcon />}>
                        Upload Crown
                      </Button>
                    </label>
                    {crownfile && (
                      <>
                        <Typography variant="body2" color="textSecondary">
                          {crownfile.name} {/* Display the uploaded file name */}
                        </Typography>
                        <Button variant="outlined" style={{ marginTop: '10px', color: primaryColor }} onClick={() => setFile2(null)}>
                          Delete
                        </Button>
                      </>
                    )}
                    {!file2 && (
                      <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px' }}>
                        Drag and drop files here or click to upload<br />
                        <Typography variant="body2" color="textSecondary" sx={{ marginTop: '10px', justifyContent: "center", textAlign: "center" }}>
                          .STL, .PLY, .OBJ, .DCM supported
                        </Typography>
                      </Typography>
                    )}
                  </Box>
                </div>
              </Card>
            </Box>
  
            {/* Right Half - Canvas */}
            <Box sx={{ flex: 1, display: 'flex', alignItems: 'center', justifyContent: 'center', position: 'relative', padding: '20px' }}>
              <canvas
                ref={canvasRef}
                width="400"
                height="300"
                style={{
                  border: '1px solid black',
                  backgroundColor: file1 || file2 ? '#ffffff' : '#ffffff', // Change background color based on file upload
                }}
              />
              {!file1 && !file2 && (
                <Typography variant="body1" color="textSecondary" sx={{ position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%)' }}>
                  Upload files to preview
                </Typography>
              )}
            </Box>
          </Box>
          <Grid item xs={12} sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <div>
                <Info /></div>
              <Typography className='fw-semibold' variant="body2" color="#000000" sx={{ marginLeft: '10px', justifyContent: 'center' }}>
                Easily upload your scans for upper and lower teeth by either dragging and dropping the files into the designated areas above or manually selecting them for upload.
              </Typography>
            </div>
            <Button
              variant="contained"
              color="primary"
              onClick={debugToggle ? debug : prep}
              // onClick={marginGo}
              disabled={loading}
              sx={{
                cursor: 'pointer',
                backgroundColor: primaryColor,
                color: '#fff',
                border: '1px solid #213F99',
                transition: 'background-color 0.3s ease',
                marginLeft: 2,
                paddingLeft: 2,
                paddingright: 2,
              }}
              startIcon={loading ? <CircularProgress size={24} color="inherit" /> : null}
            >
              {loading ? null : 'Next'}
            </Button>
          </Grid>
          {!fileValid && (
          <Typography
            variant="body2"
            color="error"
            sx={{
              textAlign: 'center',
              marginTop: '10px',
              padding: '10px',
              backgroundColor: 'rgba(255, 0, 0, 0.1)',
              borderRadius: '8px',
              border: '1px solid red',
            }}
          >
            Please Select A Valid File
          </Typography>
        )}
        </Card>
      </Box>
    </div>
  );
  
};

export default Fileselect;
