import * as THREE from "three";

class HigherOrderBezierCurve extends THREE.Curve {
    constructor(controlPoints) {
      super();
      this.controlPoints = controlPoints; // Array of THREE.Vector3
      this.degree = 5;
    }
  
    // Bézier basis function
    bernstein(i, n,t) {
      const u = 1-t;
      const mat = [u * u * u * u * u, 5 * u * u * u * u * t , 10 * u * u * u * t * t , 10 * u * u * t * t * t, 5 * u * t * t * t * t , t * t * t * t * t]
      return mat[i]
    }
  
    getPoint(t) {
      const n = this.degree;
      const point = new THREE.Vector3(0, 0, 0);
  
      for (let i = 0; i <= n; i++) {
        const b = this.bernstein(i, n, t);
        point.add(this.controlPoints[i].clone().multiplyScalar(b));
      }
  
      return point;
    }
  }
  
export { HigherOrderBezierCurve };